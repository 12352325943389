import { ArrowBackIcon, ArrowForwardIcon } from "@chakra-ui/icons"
import { Box, Button, Drawer, DrawerBody, DrawerCloseButton, DrawerContent, DrawerHeader, HStack, Heading, IconButton, StackDivider, useBoolean } from "@chakra-ui/react"
import { CoversCanvas, InteractionsCanvas, MainCanvas } from "../../../Sitemap/app/canvas/canvases"
import { doc, getFirestore, writeBatch } from "firebase/firestore"
import { getNewFirestoreDocId, getTemplateDrawer, sendHubspotCustomEvent } from "../../../../helpers"
import { isObject, mapValues, omit } from 'lodash'
import { toggleNewSitemapModal, toggleTemplateDrawer, toggleUpgradeModal } from "../../../../store/actions/ui-actions"
import { useGetFolderIdFromPath, useGetUserId, useHitSitemapsLimit, useInOrganization } from "../../../../hooks"

import { CanvasButtons } from "../../../Sitemap/app/canvas/buttons"
import { SharedCanvas } from "../../../Sitemap/app/canvas/shared"
// import amplitude from 'amplitude-js/amplitude';
import { mergeFileChange } from "../../../../store/actions/files-actions"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"
import { wait } from "../../../../../shared/helpers"

export const TemplateDrawer = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const hitSitemapsLimit = useHitSitemapsLimit()
    const [isCreating, setIsCreating] = useBoolean(false)
    const inOrganization = useInOrganization()
    const userId = useGetUserId()
    const folderId = useGetFolderIdFromPath({ returnAccountId: true })
    const { template, sitemap } = getTemplateDrawer()
    const onClose = () => dispatch(toggleTemplateDrawer({ showing: false }))
    return (
        <Drawer
            size="full"
            isOpen
            placement='bottom'
            motionPreset="none"
            onClose={onClose}
        >
            <DrawerContent bgColor="rarchy-bg-sitemap-editor">
                <DrawerCloseButton fontSize="xl" p={5} top={4} right={6} zIndex={2} />
                <DrawerHeader p={10} py={8} zIndex={1}>
                    <HStack spacing={8} divider={<StackDivider h={8} alignSelf="unset" />}>
                        <HStack spacing={4}>
                            <IconButton icon={<ArrowBackIcon fontSize="2xl" />} size="sm" variant="ghost" onClick={onClose} />
                            <Heading fontSize="xl">
                                {template?.label}
                            </Heading>
                        </HStack>
                        <Button
                            colorScheme="blue"
                            rightIcon={<ArrowForwardIcon />}
                            isLoading={isCreating}
                            onClick={async () => {

                                setIsCreating.on()

                                try {
                                    
                                    // have hit sitemaps limit
                                    if (hitSitemapsLimit) {
                                        await wait(1000)
                                        dispatch(toggleNewSitemapModal({ showing: false }))
                                        dispatch(toggleUpgradeModal({ showing: true, screen: `new-file_sitemaps`, planId: 'team' }))
                                        return;
                                    }

                                    // create from template

                                    const firestore = getFirestore()

                                    // Get a new write batch
                                    const batch = writeBatch(firestore);

                                    const newDocData = {
                                        archived: false,
                                        createdAt: new Date(),
                                        createdBy: userId,
                                        updatedAt: new Date(),
                                        updatedBy: userId,
                                        name: `${template?.name} Template`,
                                        team: folderId
                                    };

                                    const docId = getNewFirestoreDocId("sitemaps");

                                    // set doc
                                    const docRef = doc(firestore, "sitemaps", docId);
                                    batch.set(docRef, newDocData, { merge: true });

                                    // set pages (remove page colors if in free account)
                                    const pages = inOrganization ? sitemap?.docs?.pages : mapValues(sitemap?.docs?.pages, v => isObject(v) ? omit(v, 'pallette') : v);
                                    const pagesRef = doc(docRef, "data", "pages")
                                    batch.set(pagesRef, { pages, lastEdit: userId, updatedAt: new Date() }, { merge: true })
                                    // set covers
                                    const coversRef = doc(docRef, "data", "covers")
                                    batch.set(coversRef, { pages: sitemap?.docs?.covers, lastEdit: userId, updatedAt: new Date() }, { merge: true })
                                    // set sections
                                    const sectionsRef = doc(docRef, "data", "sections")
                                    batch.set(sectionsRef, { pages: sitemap?.docs?.sections, lastEdit: userId, updatedAt: new Date() }, { merge: true })
                                    // set comments
                                    const commentsRef = doc(docRef, "data", "comments")
                                    batch.set(commentsRef, { pages: {}, lastEdit: userId, updatedAt: new Date() }, { merge: true })
                                    // set website_sections
                                    const websiteSectionsRef = doc(docRef, "data", "website_sections")
                                    batch.set(websiteSectionsRef, { data: sitemap?.docs?.website_sections, lastEdit: userId, updatedAt: new Date() }, { merge: true })

                                    // commit batch to firestore
                                    await batch.commit()

                                    // amplitude tracking
                                    // amplitude.getInstance().logEvent('CREATED_NEW_SITEMAP_FROM_TEMPLATE', { id: docId, name: template?.name });
                                    // push new created sitemap event to GTM
                                    window.dataLayer.push({ 'event': 'created_sitemap', 'sitemap_type': 'new' });

                                    await wait(1000)

                                    // insert new file into files
                                    dispatch(mergeFileChange(docId, 'sitemaps', { id: docId, collection: 'sitemaps', ...newDocData }))

                                    // set showCovers
                                    localStorage.setItem(`${docId}#showCovers`, template?.type === 'covers');

                                    history.push(`/app/sitemap/${docId}`)

                                    dispatch(toggleTemplateDrawer({ showing: false }))
                                    dispatch(toggleNewSitemapModal({ showing: false }))

                                    // send event to Hubspot
                                    sendHubspotCustomEvent('created_sitemap', { sitemap_type: 'template', template_name: template?.name })

                                } catch (e) {
                                    console.error(e)
                                } finally {
                                    setIsCreating.off()
                                }
                            }}
                        >
                            Use this template
                        </Button>
                    </HStack>
                </DrawerHeader>
                <DrawerBody p={0}>
                    <SharedCanvas />
                </DrawerBody>
            </DrawerContent>
        </Drawer>
    )
}