import { Box, Button } from "@chakra-ui/react"
import { useGetSubscription, useGetUser, useInOrganization, useInUserEditorDoc } from "../../../../../../hooks"

import dayjs from 'dayjs'
import relativeTime from 'dayjs/plugin/relativeTime'
import { toggleUpgradeModal } from "../../../../../../store/actions/ui-actions"
import { useDispatch } from "react-redux"
import { useGetMonthlyScreenshotsRemaining } from "../hooks"
import { useGetScreenshotsLimitByPlan } from "../../../../../../../shared/hooks/pricing"

dayjs.extend(relativeTime)

export const RemainingScreenshots = () => {
    
    const dispatch = useDispatch()
    const screenshotsRemaining = useGetMonthlyScreenshotsRemaining()
    const monthlyScreenshots = useGetScreenshotsLimitByPlan()
    const subscription = useGetSubscription()
    const inOrganization = useInOrganization()
    const user = useGetUser()
    const inUserSitemap = useInUserEditorDoc()
    
    const refresh_date = inOrganization ? dayjs.unix(subscription?.current_period_end) : dayjs(user?.limits?.screenshots?.refresh_at?.toDate()?.getTime())
    
    return (
        <Box fontSize="sm" color="fg.muted">
            
            {`Capture ${screenshotsRemaining?.toLocaleString()} more pages until your monthly limit of ${monthlyScreenshots?.toLocaleString()} screenshots refreshes on ${refresh_date?.format('ll')}.`}

            {inUserSitemap && (
                <>
                <Button
                    size="sm"
                    variant="link"
                    color="rarchy-link"
                    fontWeight="medium"
                    ml={1.5}
                    onClick={() => {
                        dispatch(toggleUpgradeModal({ showing: true, planId: 'team', screen: 'screenshots' }))
                    }}
                >
                    Upgrade to capture more
                </Button>
                .
                </>
            )}
        </Box>
    )
}