import { getDeviceByPage, getPageColor, getRandomColor, getX, getY, shouldShowCovers } from '../utils/helpers'
import { getInUserFlow, getSitemap, getUI } from '../../../../../helpers'

import { barWidth } from '../..'
import { dragging } from '../utils/drag'
import { getPalletteColors } from '../../../../../store/actions/editor-actions'
import { mouseover } from '../utils/listeners'
import { store } from '../../../../../store'

export const colors = {}
const colorByNodeIds = {}

export const renderNodeRect = (context, d, hidden, draggingPlaceholder) => {

    const sitemap = getSitemap()
    const ui = getUI()

    const inUserFlow = getInUserFlow()
    const showCovers = shouldShowCovers(d);
    const pallette = getPalletteColors(getPageColor(!dragging ? d : dragging))

    /*** assign event colors ***/
    var randomColor
    if (hidden) {
        if (!colorByNodeIds[d.id]) {
            /*** assign new random color to node id ***/
            randomColor = getRandomColor()
            colorByNodeIds[d.id] = randomColor
            colors[randomColor] = d
            /*** assign new random color to node id ***/
        } else {
            randomColor = colorByNodeIds[d.id] // use existing assigned color
        }
    }
    /*** assign event colors ***/

    context.lineJoin = d.id // hack - needed so we can get id from rect for clickable links in PDF

    const fillStyle = ui.colorMode === 'light' ? 'white' : '#1A202C'
    if (hidden) {
        context.fillStyle = randomColor
    } else {
        if ((dragging?.started && (d?.id === dragging?.id)) || d.id === 'new-symbol') {
            context.fillStyle = fillStyle
        } else {
            context.fillStyle = showCovers ? 'transparent' : fillStyle // needs to be transparent for normal render so covers can show
        }
        // dragging placeholder fill
        if (draggingPlaceholder) context.fillStyle = ui.colorMode === 'light' ? pallette[0] : "#ffffff14"
        // stroke
        context.strokeStyle = !draggingPlaceholder ? getPageColor(d) : "transparent"
        
        // page type === 'subdirectory'
        if (d.type === 'subdirectory') {
            context.setLineDash([5, 2.5]);
            context.globalAlpha = 0.9;
        } else {
            context.globalAlpha = 1;
        }
        
    }

    renderChildRect(context, sitemap, ui, d, hidden)

    if (context.canvas.id !== 'picker-canvas') {
        const x = getX(d), y = getY(d)
        const device = getDeviceByPage(d)
        if (d._selected) context.setLineDash([9, 6]);
        roundRect(context, x, y, barWidth, d.nodeHeight, device === 'desktop' ? 5 : 15)
        context.setLineDash([]); // reset line dash
    }

    // draw larger overlap for picker canvas on mouseover (to stop jittering when moving cursor between options buttons)
    if (!draggingPlaceholder) {
        if (context.canvas.id === 'picker-canvas') {
            const isMouseoverNode = (mouseover.node && (mouseover.node.id === d.id))
            const widening = (isMouseoverNode ? inUserFlow ? 34 : 17 : 0)
            const x = getX(d) - widening, y = getY(d) - widening
            context.fillRect(x, y, barWidth + (isMouseoverNode ? inUserFlow ? 68 : 34 : 0), (d.nodeHeight + (isMouseoverNode ? inUserFlow ? 64 : 32 : 0)))
        }
    }
}

export const renderChildRect = (context, sitemap, ui, d, hidden) => {
    if (hidden) return // don't need to show on picker canvas
    const showCovers = getInUserFlow() ? true : sitemap?.showCovers
    const canvasId = context.canvas.id
    if (showCovers && canvasId !== 'covers-canvas' && canvasId !== 'interactions-canvas') return // don't show on covers canvas
    const pallette = getPalletteColors(getPageColor(d))
    if ((d._children || (dragging?.id === d?.id && ((d.children || d._children))) || (dragging && !dragging.section && dragging?.over?.id === d?.id))) {
        const x = getX(d), y = showCovers ? getY(d)/* - 112.5 */ : getY(d)
        const device = getDeviceByPage(d)
        context.fillStyle = ui.colorMode === 'light' ? 'white' : '#1A202C'
        context.strokeStyle = pallette[5]
        if (d.type === 'subdirectory') context.setLineDash([]); // reset line dash before
        roundRect(context, x + 6, y + 6, barWidth, d.nodeHeight, device === 'desktop' ? 5 : 15, false)
        if (d.type === 'subdirectory') context.setLineDash([5, 2.5]); // set back before drawing main rect
        // reset strokeStyle
        context.strokeStyle = getPageColor(d)
    }
}

export const roundRect = (ctx, x, y, width, height, radius, hidden) => {
    if (width < 2 * radius) radius = width / 2
    if (height < 2 * radius) radius = height / 2
    ctx.beginPath()
    ctx.moveTo(x + radius, y)
    ctx.arcTo(x + width, y, x + width, y + height, radius)
    ctx.arcTo(x + width, y + height, x, y + height, radius)
    ctx.arcTo(x, y + height, x, y, radius)
    ctx.arcTo(x, y, x + width, y, radius)
    ctx.closePath()
    ctx.lineWidth = 2
    ctx.fill()
    if (!hidden) ctx.stroke()
}

export const mouseoverNode = (colKey) => {
    var node = colors[colKey]
    if (node) {
        const { sitemap, flow } = store.getState()
        const inUserFlow = getInUserFlow()
        const nodes = !inUserFlow ? sitemap?.data.nodes : flow.data.nodes
        node = nodes.find(d => d.id === node.id) // get most up to date node data
        return node
    }
}