import { addCommentsChange, addCoversChange, addPageChange, addPageSectionChange, addWebsiteSectionChange, mergePagesEdits, mergeWebsiteSectionsEdits, redoUserChange, resetNodeHeights, togglePageSectionsOptionsPopover, undoUserChange, updatePageSectionsData } from '../../../../store/actions/sitemap-actions'
import { cloneDeep, compact, isEmpty, isUndefined, merge, omit, omitBy, remove } from 'lodash'
import { getEditor, getInUserFlow, getInUserSitemap } from '../../../../helpers';
import { handleRedoClickForUserFlow, handleUndoClickForUserFlow } from './user-flows';

import { chain } from '../../../../helpers/chain';
import deepmerge from 'deepmerge'
import { getCanEditInEditor } from '../../Navbar/helpers';
import { getPageName } from '../../../Sitemap/utils/app'
import { handlePageSectionsFromPageChange } from '../../../Sitemap/app/canvas/utils/page-sections/helpers';
import { store } from '../../../../store';

export const handleUndoClick = async ({ sitemap, firestore, dispatch }) => {

    const inUserSitemap = getInUserSitemap()

    const inUserFlow = getInUserFlow()
    if (inUserFlow) return handleUndoClickForUserFlow();

    const editor = getEditor()

    const { history } = sitemap;
    const lastUndo = history.undo[history.undo.length - 1];
    if (!lastUndo) return;

    const inSubfolder = sitemap?.data?.section;

    const { action } = lastUndo;
    const latestPages = await convertRootToPages(sitemap, { inSubfolder });

    var data = {}, coversData = {}, commentsData = {};
    var change = {}, coversChange = {}, commentsChange = {};

    // don't undo if a rename input is visible
    if (editor?.ui.CanvasTextEditor.showing) return;
    // website sections
    if (action.includes('website-section')) return handleUndoClickForWebsiteSection({ lastUndo, action, dispatch });
    // page sections
    if (action.includes('page-section')) return handleUndoClickForPageSection({ sitemap, lastUndo, action, dispatch });

    switch (action) {
        case 'rename':
            data[lastUndo.node] = { name: lastUndo.data.oldName };
            // change
            change = {
                id: new Date().getTime(),
                data: [{
                    id: lastUndo.node,
                    name: lastUndo.data.oldName
                }],
            };
            dispatch(resetNodeHeights([lastUndo.node]));
            break;
        case 'add':
            latestPages[lastUndo.node] = undefined;
            // change
            change = {
                id: new Date().getTime(),
                data: [{
                    action: 'remove',
                    id: lastUndo.node,
                }, ...lastUndo.childrenWithOldIndexes]
            };
            break;
        case 'remove': {
            // covert data to map of objects to merge into root
            data = convertDataForMerge([...lastUndo.childrenWithOldIndexes, ...lastUndo.data]);
            // add change with lastUndo (array of data with ids)
            change = { id: new Date().getTime(), data: [...lastUndo.childrenWithOldIndexes, ...lastUndo.data] };
            /*** get any page sections data ***/
            const pageSectionsData = getPageSectionsFromDelete(lastUndo.data);
            if (!isEmpty(pageSectionsData)) handlePageSectionsFromPageChange('clone', { pages: pageSectionsData }); // can use same logic as clone here
            /*** get any page sections data ***/
            /*** get any covers data ***/
            coversData = getCoversFromDelete(lastUndo.data);
            coversChange = { id: new Date().getTime(), data: coversData }
            /*** get any covers data ***/
            /*** get any comments data if not in user sitemap ***/
            if (!inUserSitemap) {
                commentsData = getCommentsFromDelete(lastUndo.data);
                commentsChange = { id: new Date().getTime(), data: commentsData }
            }
            /*** get any comments data if not in user sitemap ***/
            break;
        }
        case 'clone': {
            change = { id: new Date().getTime(), data: [...lastUndo.childrenWithOldIndexes] }
            coversChange = { id: new Date().getTime(), data: [] };
            lastUndo.data.forEach(page => {
                latestPages[page.id] = undefined;
                // remove pages
                change.data.push({ action: 'remove', id: page.id });
                // get any covers
                if (page.cover) coversChange.data.push({ [page.id]: firestore.FieldValue.delete() });
            });
            /*** get any page sections data ***/
            const pageSectionsDataForCloneUndo = getPageSectionsFromDelete(lastUndo.data);
            if (!isEmpty(pageSectionsDataForCloneUndo)) handlePageSectionsFromPageChange('remove', { pages: Object.keys(pageSectionsDataForCloneUndo) });
            /*** get any page sections data ***/
            break;
        }
        case 'reorder':
            // covert data to map of objects to merge into root
            data = convertDataForMerge(lastUndo.prevData)
            // add change with lastUndo (array of data with ids)
            change = {
                id: new Date().getTime(),
                data: lastUndo.prevData,
            }
            break
        case 'color':
            data[lastUndo.node] = { pallette: lastUndo.data.oldPallette }
            // change
            change = {
                id: new Date().getTime(),
                data: [{
                    id: lastUndo.node,
                    pallette: lastUndo.data.oldPallette
                }]
            };
            break
        case 'type':
            data[lastUndo.node] = { type: lastUndo.data.oldType, name: lastUndo.data.oldName }
            // change
            change = {
                id: new Date().getTime(),
                data: [{
                    id: lastUndo.node,
                    type: lastUndo.data.oldType,
                    name: lastUndo.data.oldName
                }]
            };
            break
        default:
            break
    };

    /*** merge page edits ***/
    const mergedPages = merge(latestPages, data);
    store.dispatch(mergePagesEdits({ pages: mergedPages }))
    /*** merge page edits ***/

    // add undo user change in redux
    setTimeout(() => dispatch(undoUserChange()), 100);

    // add user change in redux
    setTimeout(() => {
        dispatch(addPageChange({ change }));
        // if covers
        if (!isEmpty(coversChange.data)) dispatch(addCoversChange({ change: coversChange, covers: deepmerge.all(coversChange.data) }));
        // if comments
        if (!isEmpty(commentsChange.data)) dispatch(addCommentsChange({ change: commentsChange }));
    }, 500);
};

export const handleRedoClick = async ({ sitemap, firestore, dispatch }) => {

    const canEdit = getCanEditInEditor()
    if (!canEdit) return;

    const inUserSitemap = getInUserSitemap()

    const inUserFlow = getInUserFlow();
    if (inUserFlow) return handleRedoClickForUserFlow();

    const editor = getEditor()

    const { history } = sitemap;
    const lastRedo = history.redo[history.redo.length - 1];
    if (!lastRedo) return;

    const inSubfolder = sitemap?.data?.section;

    const { action } = lastRedo;

    // don't redo if a rename input is visible
    if (editor?.ui.CanvasTextEditor.showing) return;
    // website sections
    if (action.includes('website-section')) return handleRedoClickForWebsiteSection({ lastRedo, action });
    // page sections
    if (action.includes('page-section')) return handleRedoClickForPageSection({ sitemap, lastRedo, action });

    const latestPages = await convertRootToPages(sitemap, { inSubfolder });

    var data = {};
    var change = {}, coversChange = {}, commentsChange = {};

    switch (action) {
        case 'rename':
            data[lastRedo.node] = { name: lastRedo.data.newName };
            // change
            change = {
                id: new Date().getTime(),
                data: [{
                    id: lastRedo.node,
                    name: lastRedo.data.newName
                }],
            };
            dispatch(resetNodeHeights([lastRedo.node]));
            break;
        case 'add':
            // covert data to map of objects to merge into root
            data = convertDataForMerge(lastRedo.data);
            // add change with lastRedo (array of data with ids)
            change = {
                id: new Date().getTime(),
                data: lastRedo.data,
            };
            break;
        case 'remove': {
            change = { id: new Date().getTime(), data: [...lastRedo.childrenWithNewIndexes] };
            coversChange = { id: new Date().getTime(), data: [] };
            commentsChange = { id: new Date().getTime(), data: [] };
            lastRedo.data.forEach(page => {
                latestPages[page.id] = undefined;
                change.data.push({ action: 'remove', id: page.id });
                // get any covers
                if (page.cover && !isEmpty(coversChange.data)) coversChange.data.push({ [page.id]: firestore.FieldValue.delete() });
                // get any comments
                if (!inUserSitemap) {
                    if (page.comments && !isEmpty(commentsChange.data)) commentsChange.data.push({ [page.id]: firestore.FieldValue.delete() });
                }
            });
            /*** get any page sections data ***/
            const pageSectionsData = getPageSectionsFromDelete(lastRedo.data);
            if (!isEmpty(pageSectionsData)) handlePageSectionsFromPageChange('remove', { pages: Object.keys(pageSectionsData) });
            /*** get any page sections data ***/
            break;
        }
        case 'clone': {
            // covert data to map of objects to merge into root
            data = convertDataForMerge([...lastRedo.data, ...lastRedo.childrenWithNewIndexes]);
            // add change with lastRedo (array of data with ids)
            change = { id: new Date().getTime(), data: lastRedo.data };
            /*** get any page sections data ***/
            const pageSectionsDataToRedoClone = getPageSectionsFromDelete(lastRedo.data);
            if (!isEmpty(pageSectionsDataToRedoClone)) handlePageSectionsFromPageChange('clone', { pages: pageSectionsDataToRedoClone });
            /*** get any page sections data ***/
            // re-add any cloned covers that were removed
            coversChange = { id: new Date().getTime(), data: [] };
            lastRedo.data.forEach(page => { if (page.cover) coversChange.data.push({ [page.id]: page.cover }); })
            break;
        }
        case 'reorder':
            // covert data to map of objects to merge into root
            data = convertDataForMerge(lastRedo.data);
            // add change with lastRedo (array of data with ids)
            change = {
                id: new Date().getTime(),
                data: lastRedo.data,
            };
            break;
        case 'color':
            data[lastRedo.node] = { pallette: lastRedo.data.pallette }
            // change
            change = {
                id: new Date().getTime(),
                data: [{
                    id: lastRedo.node,
                    pallette: lastRedo.data.pallette
                }],
            }
            break
        case 'type':
            data[lastRedo.node] = { type: lastRedo.data.type, name: lastRedo.data.name }
            // change
            change = {
                id: new Date().getTime(),
                data: [{
                    id: lastRedo.node,
                    type: lastRedo.data.type,
                    name: lastRedo.data.name
                }],
            }
            break
        default:
            break;
    }

    /*** merge page edits ***/
    const mergedPages = merge(latestPages, data);
    store.dispatch(mergePagesEdits({ pages: mergedPages }))
    /*** merge page edits ***/

    // add undo user change in redux
    setTimeout(() => dispatch(redoUserChange()), 100);

    // add user change in redux
    setTimeout(() => {
        dispatch(addPageChange({ change }));
        // if covers
        if (!isEmpty(coversChange.data)) dispatch(addCoversChange({ change: coversChange, covers: deepmerge.all(coversChange.data) }));
        // if comments
        if (!isEmpty(commentsChange.data)) dispatch(addCommentsChange({ change: commentsChange }));
    }, 500);
};

const convertDataForMerge = data => {
    return chain(data)
        .keyBy('id')
        .mapValues(v =>
            omitBy(omit(v, ['action', 'id', 'cover', 'comments']), isUndefined)
        )
        .value();
};

/*** page sections data ***/
const getPageSectionsFromDelete = data => {
    const obj = {};
    const pages = compact(data.filter(page => { if (!isEmpty(page.pageSections)) return page; return null; }));
    pages.forEach(page => obj[page.id] = page.pageSections);
    return obj;
};
/*** page sections data ***/

/*** covers data ***/
const getCoversFromDelete = data => {
    const pages = compact(data.filter(page => { if (page.cover) return page; return null; }));
    return pages.map(page => { return { [page.id]: page.cover } });
};
/*** covers data ***/

/*** comments data ***/
const getCommentsFromDelete = data => {
    const pages = compact(data.filter(page => { if (page.comments) return page; return null; }));
    return pages.map(page => { return { [page.id]: page.comments } });
};
/*** comments data ***/

export const handleUndoClickForWebsiteSection = ({ lastUndo, action }) => {

    let change = {};
    let immediateUpdateData = {};
    let changeData = [];

    let oldSectionIndexesKeys = lastUndo.data.oldSectionIndexes ? Object.keys(lastUndo.data.oldSectionIndexes) : [];

    switch (action) {
        case 'website-section-add':
            // new section indexes
            oldSectionIndexesKeys.forEach(sectionId => { // NO ACTION NEEDED
                changeData.push({ id: sectionId, ...lastUndo.data.oldSectionIndexes[sectionId] });
                immediateUpdateData[sectionId] = { ...lastUndo.data.oldSectionIndexes[sectionId] };
            });
            // remove section 
            changeData.push({ action: 'website-section-remove', id: lastUndo.data.section.id, ...undefined }); // NEED ACTION TO ENSURE FIELDVALUE.DELETE() IS SET IN SAVEWEBSITESECTIONCHANGES
            immediateUpdateData[lastUndo.data.section.id] = undefined;
            // change
            change = {
                id: new Date().getTime(),
                data: changeData
            };
            break;
        case 'website-section-rename':
            // renamed section 
            changeData.push({ id: lastUndo.data.section.id, title: lastUndo.data.oldTitle });
            immediateUpdateData[lastUndo.data.section.id] = { title: lastUndo.data.oldTitle };
            // change
            change = {
                id: new Date().getTime(),
                data: changeData
            };
            break;
        case 'website-section-reorder':
            oldSectionIndexesKeys.forEach(sectionId => { // NO ACTION NEEDED
                changeData.push({ id: sectionId, ...lastUndo.data.oldSectionIndexes[sectionId] });
                immediateUpdateData[sectionId] = { ...lastUndo.data.oldSectionIndexes[sectionId] }
            });
            change = {
                id: new Date().getTime(),
                data: changeData
            };
            break;
        case 'website-section-remove':
            // old section indexes
            oldSectionIndexesKeys.forEach(sectionId => { // NO ACTION NEEDED
                changeData.push({ id: sectionId, ...lastUndo.data.oldSectionIndexes[sectionId] });
                immediateUpdateData[sectionId] = { ...lastUndo.data.oldSectionIndexes[sectionId] }
            });
            // removed section
            changeData.push(lastUndo.data.section)
            immediateUpdateData[lastUndo.data.section.id] = { ...lastUndo.data.section }
            // change
            change = {
                id: new Date().getTime(),
                data: changeData,
            };
            break;
        default:
            break;
    };

    // add undo user change in redux
    store.dispatch(undoUserChange());
    // add user change in redux
    store.dispatch(addWebsiteSectionChange({ change }));
    /*** merge page edits ***/
    store.dispatch(mergeWebsiteSectionsEdits({ website_sections: immediateUpdateData }));
    /*** merge page edits ***/
};

export const handleRedoClickForWebsiteSection = ({ lastRedo, action }) => {

    let change = {};
    let immediateUpdateData = {};
    let changeData = [];

    let newSectionIndexesKeys = lastRedo.data.newSectionIndexes ? Object.keys(lastRedo.data.newSectionIndexes) : [];

    switch (action) {
        case 'website-section-add':
            // new section indexes
            newSectionIndexesKeys.forEach(sectionId => { // NO ACTION NEEDED
                changeData.push({ id: sectionId, ...lastRedo.data.newSectionIndexes[sectionId] });
                immediateUpdateData[sectionId] = { ...lastRedo.data.newSectionIndexes[sectionId] }
            });
            // removed section
            changeData.push(lastRedo.data.section)
            immediateUpdateData[lastRedo.data.section.id] = { ...lastRedo.data.section }
            // change
            change = {
                id: new Date().getTime(),
                data: changeData,
            };
            break;
        case 'website-section-rename':
            // renamed section 
            changeData.push({ id: lastRedo.data.section.id, title: lastRedo.data.section.title });
            immediateUpdateData[lastRedo.data.section.id] = { title: lastRedo.data.section.title };
            // change
            change = {
                id: new Date().getTime(),
                data: changeData
            };
            break;
        case 'website-section-reorder':
            newSectionIndexesKeys.forEach(sectionId => { // NO ACTION NEEDED
                changeData.push({ id: sectionId, ...lastRedo.data.newSectionIndexes[sectionId] });
                immediateUpdateData[sectionId] = { ...lastRedo.data.newSectionIndexes[sectionId] }
            });
            change = {
                id: new Date().getTime(),
                data: changeData
            };
            break;
        case 'website-section-remove':
            // new section indexes
            newSectionIndexesKeys.forEach(sectionId => { // NO ACTION NEEDED
                changeData.push({ id: sectionId, ...lastRedo.data.newSectionIndexes[sectionId] });
                immediateUpdateData[sectionId] = { ...lastRedo.data.newSectionIndexes[sectionId] };
            });
            // removed section 
            changeData.push({ action: 'website-section-remove', id: lastRedo.data.section.id, ...undefined }); // NEED ACTION TO ENSURE FIELDVALUE.DELETE() IS SET IN SAVE WEBSITESECTIONCHANGES
            immediateUpdateData[lastRedo.data.section.id] = undefined;
            // change
            change = {
                id: new Date().getTime(),
                data: changeData
            };
            break;
        default:
            break;
    };

    // add redo user change in redux
    store.dispatch(redoUserChange());
    // add user change in redux
    store.dispatch(addWebsiteSectionChange({ change }));
    /*** merge page edits ***/
    store.dispatch(mergeWebsiteSectionsEdits({ website_sections: immediateUpdateData }));
    /*** merge page edits ***/
};

export const handleUndoClickForPageSection = ({ sitemap, lastUndo, action }) => {

    var sections = cloneDeep(sitemap?.data.page_sections);

    var change = {};
    var immediateUpdateData = {};

    const { pageId, section } = lastUndo.data;

    switch (action) {
        case 'page-section-new':
            change = {
                id: new Date().getTime(),
                data: [{ action: 'page-section-remove', pageId, section }] // MAKE SURE ACTION STAYS OR WONT UPDATE FIRESTORE WITH UNDO
            };
            /*** update immediately ***/
            remove(sections[pageId], { id: section.id });
            immediateUpdateData = sections;
            store.dispatch(togglePageSectionsOptionsPopover({ showing: false }));
            /*** update immediately ***/
            break;
        case 'page-section-rename':
            change = {
                id: new Date().getTime(),
                data: [{ ...lastUndo.data, section: { id: lastUndo.data.section.id, title: lastUndo.data.oldTitle } }] // NO ACTION NEEDED
            };
            /*** update immediately ***/
            sections[lastUndo.data.pageId].map(s => {
                if (s.id.toString() === lastUndo.data.section.id.toString()) s.title = lastUndo.data.oldTitle;
                return s;
            })
            immediateUpdateData = sections;
            /*** update immediately ***/
            break;
        case 'page-section-reorder':
            var changeData = [];
            var sectionKeys = Object.keys(lastUndo.data.oldSectionIndexes);
            sectionKeys.forEach(sectionId => { // NO ACTION NEEDED
                changeData.push({
                    pageId: lastUndo.data.pageId,
                    section: { id: sectionId, index: lastUndo.data.oldSectionIndexes[sectionId] }
                });
            });
            change = {
                id: new Date().getTime(),
                data: changeData
            };
            /*** update immediately ***/
            sections[lastUndo.data.pageId].map(s => {
                if (lastUndo.data.oldSectionIndexes[s.id] >= 0) {
                    s.index = lastUndo.data.oldSectionIndexes[s.id];
                }
                return s;
            });
            immediateUpdateData = sections;
            /*** update immediately ***/
            break;
        case 'page-section-color': {
            const { oldColor } = lastUndo.data;
            change = {
                id: new Date().getTime(),
                data: [{ action: 'page-section-color', ...lastUndo.data, section: { ...lastUndo.data.section, color: oldColor === undefined ? 'delete' : oldColor } }] // ACTION NEEDED
            };
            /*** update immediately ***/
            sections[lastUndo.data.pageId].map(s => {
                if (s.id.toString() === lastUndo.data.section.id.toString()) s.color = oldColor;
                return s;
            })
            immediateUpdateData = sections;
            /*** update immediately ***/
            break;
        }
        case 'page-section-clone':
            change = {
                id: new Date().getTime(),
                data: [{ action: 'page-section-remove', ...lastUndo.data }], // MAKE SURE ACTION IS 'REMOVE'
            };
            /*** update immediately ***/
            remove(sections[pageId], { id: section.id });
            immediateUpdateData = sections;
            /*** update immediately ***/
            break;
        case 'page-section-wireframe': {
            const { oldWireframe } = lastUndo.data;
            change = {
                id: new Date().getTime(),
                data: [{ action: 'page-section-wireframe', ...lastUndo.data, section: { ...lastUndo.data.section, wireframe: oldWireframe === undefined ? 'delete' : oldWireframe } }] // ACTION NEEDED
            };
            /*** update immediately ***/
            sections[lastUndo.data.pageId].map(s => {
                if (s.id.toString() === lastUndo.data.section.id.toString()) s.wireframe = oldWireframe;
                return s;
            })
            immediateUpdateData = sections;
            /*** update immediately ***/
            break;
        }
        case 'page-section-remove':
            change = {
                id: new Date().getTime(),
                data: [{ ...lastUndo.data }], // MAKE SURE ACTION IS REMOVED OR WILL UPDATE FIRESTORE WITH DELETE ON UNDO
            };
            /*** update immediately ***/
            sections[lastUndo.data.pageId].push(lastUndo.data.section);
            immediateUpdateData = sections;
            /*** update immediately ***/
            break;
        default:
            break;
    }
    // immediately update sections data
    store.dispatch(updatePageSectionsData(immediateUpdateData));
    // add undo user change in redux
    store.dispatch(undoUserChange());
    // add user change in redux
    store.dispatch(addPageSectionChange({ change }));
    // reset node heights (HAS TO BE LAST)
    if (["page-section-rename", "page-section-wireframe"].includes(action)) store.dispatch(resetNodeHeights([lastUndo.data.pageId], { update: true }));
};

export const handleRedoClickForPageSection = ({ sitemap, lastRedo, action }) => {

    var sections = cloneDeep(sitemap?.data.page_sections);

    var change = {};
    var immediateUpdateData = {};

    const { pageId, section } = lastRedo.data;

    switch (action) {
        case 'page-section-new':
            change = {
                id: new Date().getTime(),
                data: [{ ...lastRedo.data }], // NO ACTION NEEDED
            };
            /*** update immediately ***/
            sections[lastRedo.data.pageId].push({ ...lastRedo.data.section, title: '', new: undefined }); // don't want to show popover when redoing
            immediateUpdateData = sections;
            /*** update immediately ***/
            break;
        case 'page-section-rename':
            change = {
                id: new Date().getTime(),
                data: [{ ...lastRedo.data, section: { id: lastRedo.data.section.id, title: lastRedo.data.newTitle } }] // NO ACTION NEEDED
            };
            /*** update immediately ***/
            sections[lastRedo.data.pageId].map(s => {
                if (s.id.toString() === lastRedo.data.section.id.toString()) s.title = lastRedo.data.newTitle;
                return s;
            })
            immediateUpdateData = sections;
            /*** update immediately ***/
            break;
        case 'page-section-reorder':
            var changeData = [];
            var sectionKeys = Object.keys(lastRedo.data.newSectionIndexes);
            sectionKeys.forEach(sectionId => { // NO ACTION NEEDED
                changeData.push({
                    pageId: lastRedo.data.pageId,
                    section: { id: sectionId, index: lastRedo.data.newSectionIndexes[sectionId] }
                });
            });
            change = {
                id: new Date().getTime(),
                data: changeData
            };
            /*** update immediately ***/
            sections[lastRedo.data.pageId].map(s => {
                if (lastRedo.data.newSectionIndexes[s.id] >= 0) {
                    s.index = lastRedo.data.newSectionIndexes[s.id];
                }
                return s;
            });
            immediateUpdateData = sections;
            /*** update immediately ***/
            break;
        case 'page-section-color': {
            const { newColor } = lastRedo.data;
            change = {
                id: new Date().getTime(),
                data: [{ action: 'page-section-color', ...lastRedo.data, section: { ...lastRedo.data.section, color: newColor === undefined ? 'delete' : newColor } }] // ACTION NEEDED
            };
            /*** update immediately ***/
            sections[lastRedo.data.pageId].map(s => {
                if (s.id.toString() === lastRedo.data.section.id.toString()) s.color = newColor;
                return s;
            })
            immediateUpdateData = sections;
            /*** update immediately ***/
            break;
        }
        case 'page-section-clone':
            change = {
                id: new Date().getTime(),
                data: [{ ...lastRedo.data }], // NO ACTION NEEDED
            };
            /*** update immediately ***/
            sections[lastRedo.data.pageId].push(lastRedo.data.section);
            immediateUpdateData = sections;
            /*** update immediately ***/
            break;
        case 'page-section-wireframe': {
            const { newWireframe } = lastRedo.data;
            change = {
                id: new Date().getTime(),
                data: [{ action: 'page-section-wireframe', ...lastRedo.data, section: { ...lastRedo.data.section, wireframe: newWireframe === undefined ? 'delete' : newWireframe } }] // ACTION NEEDED
            };
            /*** update immediately ***/
            sections[lastRedo.data.pageId].map(s => {
                if (s.id.toString() === lastRedo.data.section.id.toString()) s.wireframe = newWireframe;
                return s;
            })
            immediateUpdateData = sections;
            /*** update immediately ***/
            break;
        }
        case 'page-section-remove':
            change = {
                id: new Date().getTime(),
                data: [{ action: 'page-section-remove', pageId, section }] // MAKE SURE ACTION STAYS OR WONT UPDATE FIRESTORE WITH DELETE ON REDO
            };
            /*** update immediately ***/
            remove(sections[pageId], { id: section.id });
            immediateUpdateData = sections;
            /*** update immediately ***/
            break;
        default:
            break;
    }
    // immediately update sections data
    store.dispatch(updatePageSectionsData(immediateUpdateData));
    // add redo user change in redux
    store.dispatch(redoUserChange());
    // add user change in redux
    store.dispatch(addPageSectionChange({ change }));
    // reset node heights (HAS TO BE LAST)
    if (["page-section-rename", "page-section-wireframe"].includes(action)) store.dispatch(resetNodeHeights([lastRedo.data.pageId], { update: true }));
};

const convertRootToPages = async (sitemap, opts = {}) => {
    const { inSubfolder, undo, redo } = opts;
    const { pages } = sitemap?.docs;
    const root = inSubfolder ? sitemap?.data.section : sitemap?.data.root;
    const data = {};
    // main pages
    recurse(root);
    // pages in website sections
    await Promise.all(root.website_sections.map(r => recurse(r)));
    function recurse(d) {
        if (d.children) d.children.forEach(recurse);
        if (d._children) d._children.forEach(recurse);
        if (d.website_section && d.website_section === d.id) return; // don't include homepages for webste-sections
        data[d.id] = {
            name: getPageName(d.name),
            index: d.index,
            url: d.url,
            pallette: d.pallette,
            parent: !inSubfolder ? (d.parent ? d.parent.id : null) : (!d.parent && (d.id === sitemap?.data.section.id)) ? pages[d.id].parent : d.parent.id,
        };
        if (d.website_section) data[d.id].website_section = d.website_section // website section
    }
    return data;
};