import { AddIcon, EmailIcon } from "@chakra-ui/icons"
import { Box, Circle, Divider, Icon } from "@chakra-ui/react"
import { useHasPendingInvitations, useInOrganization } from "../../../hooks"

import { BsTrash } from "react-icons/bs"
import { Link } from "react-router-dom"
import { NavItem } from "../Components/NavItem"
import { toggleUpgradeModal } from "../../../store/actions/ui-actions"
import { useDispatch } from "react-redux"
import { useHistory } from "react-router-dom"

export const Footer = () => {
    const dispatch = useDispatch()
    const history = useHistory()
    const inOrganization = useInOrganization()
    const hasPendingInvitations = useHasPendingInvitations()
    return (
        <Box pt={3}>
            <NavItem icon={<Icon as={AddIcon} fontSize="md" mx={1} />} label="Invite members" onClick={() => {
                if (inOrganization) {
                    history.push("/app/settings/people")
                } else {
                    dispatch(toggleUpgradeModal({ showing: true, planId: 'team', lockPlan: true, screen: 'invite-team' }))
                }
            }} />
            {inOrganization && (
                <>
                    <Divider my={3} borderColor="rarchy-border-dark" />
                    <Link to="/trash"><NavItem icon={<Icon as={BsTrash} />} label="Trash" /></Link>
                </>
            )}
            {hasPendingInvitations && (
                <>
                    <Divider my={3} />
                    <Link to="/invitations">
                        <NavItem icon={<EmailIcon mt={-0.5} mr={0.5} fontSize="md" />} endElement={<Circle size={2} bg="red.500" />} label="Invitations" />
                    </Link>
                </>
            )}
        </Box>
    )
}