import { Box, Stack } from "@chakra-ui/react"
import { TRIAL_DAYS, useCanStartNewTrial, useGetMonthlyPlanPriceWithAddons, useGetPriceWithCurrencySymbol, useGetTrialEndDate, useIsTrialing, useIsTrialingOrCanStartNewTrial } from "../../../../shared/hooks/pricing"
import { useGetAnnualPlanPrice, useGetShortPlanId } from "../../../../shared/hooks/"
import { useGetUpgradeModal, useInOrganization } from "../../../hooks"

import { useFormikContext } from "formik"

export const PayToday = ({ forAddons }) => {

    // in plan
    const inOrganization = useInOrganization()
    const isTrialing = useIsTrialing()

    const dueDate = useGetTrialEndDate()

    // starting new trial
    const canStartNewTrial = useCanStartNewTrial()

    const isTrialingOrCanStartNewTrial = useIsTrialingOrCanStartNewTrial()

    const { values } = useFormikContext()
    const { billingInterval } = values

    const { planId } = useGetUpgradeModal()
    const shortPlanId = useGetShortPlanId() // use this if not in upgrade modal (i.e, when in add user modal)

    const monthlyPrice = useGetMonthlyPlanPriceWithAddons({ planId: planId || shortPlanId, seats: values?.seats })
    const yearlyPrice = useGetAnnualPlanPrice(monthlyPrice)

    const priceToUse = billingInterval === 'month' ? monthlyPrice : yearlyPrice

    const priceWithCurrencySymbol = useGetPriceWithCurrencySymbol(priceToUse)
    
    return (
        <Stack w="full">
            {(canStartNewTrial || isTrialing) && (
                <Stack direction="row" fontSize="sm" color="fg.muted" justify="space-between">
                    <Box>
                        {`Due ${dueDate.format('ll')}`}
                    </Box>
                    <Box>{`${forAddons ? ' + ' : ''}${priceWithCurrencySymbol} ${isTrialing ? ` / ${billingInterval} ` : ''}`}</Box>
                </Stack>
            )}
            <Stack direction="row" fontSize="sm" fontWeight="semibold" justify="space-between">
                <Box>
                    Due today
                    <Box display="inline" fontWeight="normal" color="fg.muted">{inOrganization && !isTrialingOrCanStartNewTrial ? ' (before pro-rata)' : ''}</Box>
                    {canStartNewTrial && (
                        <Box
                            display="inline"
                            color="rarchy-green"
                        >
                            {` (${TRIAL_DAYS} days free)`}
                        </Box>
                    )}
                </Box>
                <Box>
                    {forAddons ? ' + ' : ''} {useGetPriceWithCurrencySymbol((canStartNewTrial || isTrialing) ? 0 : priceToUse)}
                </Box>
            </Stack>
        </Stack>
    )
}