import { Menu, MenuButton, MenuList, useBoolean } from "@chakra-ui/react";

import { MenuItem } from "./MenuItem";
import { motionPropsZeroAnimation } from "../../../../../helpers";

export const Submenu = ({ text, icon, divider, children }) => {

    const [submenuOpen, setSubmenuOpen] = useBoolean(false);

    const closeSubmenu = () => {
        if (submenuOpen) {
            setSubmenuOpen.off();
        }
    };

    const toggleSubmenu = () => {
        setSubmenuOpen.toggle();
    };

    return (
        <Menu isOpen={submenuOpen} placement="right-start" motionProps={motionPropsZeroAnimation} onClose={closeSubmenu} offset={[-2.5, 5]}>
            <MenuButton as={MenuItem} submenu divider={divider} text={text} icon={icon} onClick={toggleSubmenu}>
            </MenuButton>
            <MenuList py={1}>
                {children}
            </MenuList>
        </Menu>
    );
}