import { compact } from 'lodash';
import { getURL } from '../../../../../store/actions/sitemap-actions';
import { store } from '../../../../../store';

export { exportCSV } from './csv';
export { exportSitemap } from './xml';
export { exportSVG } from './svg';

export const getURLs = () => {
  const state = store.getState();
  const { sitemap } = state;
  const { root, section: subfolder } = sitemap?.data || {};
  // hierarchical data to flat data for force layout
  let urls = [];
  //
  function getURLFromNode(node) {
    // push url to array
    if (!node?.type) urls.push(getURL(node.id)); // don't include subdirectory pages
    // recurse
    node.children?.forEach(getURLFromNode);
    node._children?.forEach(getURLFromNode);
  }
  // from section or root
  getURLFromNode(subfolder || root);
  // sort URLS
  urls.sort();
  // website sections
  (subfolder || root).website_sections?.forEach(getURLFromNode)
  // return URLs
  return compact(urls);
};
