import { Button, Divider, Icon, ListItem, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Text, UnorderedList, VStack, useDisclosure } from "@chakra-ui/react"
import { addPageChange, mergePagesEdits } from "../../../../../store/actions/sitemap-actions"

import { BsQuestionCircle } from "react-icons/bs"
import { CheckIcon } from "@chakra-ui/icons"
import { MenuItem } from "./MenuItem"
import { PiFileBold } from "react-icons/pi"
import { Submenu } from "./Submenu"
import { getCanEditInEditor } from "../../../../Editor/Navbar/helpers"
import { useDispatch } from "react-redux"
import { useFirestore } from "react-redux-firebase"
import { useGetSitemap } from "../../../../../hooks"

export const PageType = ({ iconFontSize }) => {

    const { isOpen, onOpen, onClose } = useDisclosure()

    const firestore = useFirestore()
    const dispatch = useDispatch()

    const sitemap = useGetSitemap()
    const { ContextMenuDropdown } = sitemap?.ui || {}
    const { node } = ContextMenuDropdown

    const isPage = !node?.type
    const isSubdirectory = node?.type === 'subdirectory'

    const handleSelect = (type) => {
        savePageTypeChange({ type, node, sitemap, firestore, dispatch });
    }

    return (
        <Submenu id="type" icon={<PiFileBold fontSize={iconFontSize} />} text="Page Type">
            <MenuItem
                key="page"
                icon={isPage ? <CheckIcon /> : null}
                text={<Text pl={isPage ? 0 : 6}>Page</Text>}
                onClick={() => handleSelect(null)}
            />
            <MenuItem
                key="subdirectory"
                icon={isSubdirectory ? <CheckIcon /> : null}
                text={<Text pl={isSubdirectory ? 0 : 6} onClick={() => handleSelect('subdirectory')}>Subdirectory</Text>}
            />
            <Button py={2} rightIcon={<Icon as={BsQuestionCircle} fontSize="xs" />} pl={9} iconSpacing={2.5} onClick={onOpen} size="sm" fontSize="13px" variant="link" color="rarchy-link" fontWeight="normal">Learn more</Button>

            <Modal isOpen={isOpen} onClose={onClose} size="lg">
                <ModalOverlay />
                <ModalContent fontSize="15px">
                    <ModalCloseButton />
                    <ModalHeader>
                        Page Types
                    </ModalHeader>
                    <ModalBody pb={9}>
                        <VStack align="start">
                            <Text>
                                When we import a website, we may classify some URLs as 'subdirectory' pages.
                            </Text>
                            <Text>
                                These are pages we have found that don't have their own URL, but exist as a subdirectory in another page's URL.
                            </Text>
                            <Text>
                                As an example, creating a small sitemap from the below URLs:
                                <UnorderedList mt={2}>
                                    <ListItem>
                                        https://rarchy.com
                                    </ListItem>
                                    <ListItem>
                                        https://rarchy.com/solutions/agencies
                                    </ListItem>
                                </UnorderedList>
                            </Text>
                            <Text>
                                This would create the following hierarchy in our sitemap editor:
                                <Text mt={3}>
                                    <strong>Home</strong> (page)
                                </Text>
                                <Text pl={4}>
                                    - <strong>/solutions</strong> (subdirectory)
                                </Text>
                                <Text pl={8}>
                                    -- <strong>Agencies</strong> (page)
                                </Text>
                            </Text>
                            <Text>
                                As '/solutions' is not actually a URL on rarchy.com, but is needed to act as the hierarchal parent for '/solutions/agencies', we mark this as a 'subdirectory' page.
                            </Text>
                            <Divider my={2.5} />
                            <Text>
                                <strong>Note:</strong> subdirectory pages won't be included in:
                                <UnorderedList mt={2}>
                                    <ListItem>
                                        Search page count
                                    </ListItem>
                                    <ListItem>
                                        XML/CSV exports
                                    </ListItem>
                                    <ListItem>
                                        Screenshot page selection
                                    </ListItem>
                                </UnorderedList>
                            </Text>
                        </VStack>
                    </ModalBody>
                </ModalContent>
            </Modal>
        </Submenu>
    )
}

const savePageTypeChange = ({ type, node, sitemap, firestore, dispatch }) => {

    const canEdit = getCanEditInEditor();
    // ensure user can save
    if (!canEdit) return;

    const pageFromSitemapPagesDoc = sitemap?.docs.pages?.[node?.id];

    // change name to url
    let name = node?.name;
    if (node?.url) name = `/${node?.url?.split("/").pop()}`

    // save
    var obj = [{ action: 'type', id: node.id, type }];
    const change = { id: new Date().getTime(), data: obj };
    const history = {
        action: 'type',
        node: node.id,
        data: { type, oldType: pageFromSitemapPagesDoc?.type || null, name, oldName: node?.name }
    };

    // add page change in redux
    dispatch(addPageChange({ change, history }));

    /*** merge page edits ***/
    dispatch(mergePagesEdits({ pages: { [node.id]: { ...pageFromSitemapPagesDoc, type, name } } }))
    /*** merge page edits ***/

}