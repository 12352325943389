import { Button, Flex, NumberDecrementStepper, NumberIncrementStepper, NumberInput, NumberInputField, NumberInputStepper, Stack, Text } from "@chakra-ui/react"
import { useGetAddUsersModal, useGetUpgradeModal } from "../../../hooks"

import { toggleUpgradeModal } from "../../../store/actions/ui-actions"
import { useDispatch } from "react-redux"
import { useFormikContext } from "formik"
import { useIsEnterprisePlanBetterValue } from "../../../../shared/hooks/pricing"

export const AdditionalSeats = () => {
    const dispatch = useDispatch()
    const { values, setFieldValue } = useFormikContext()
    const { screen, planId, stripeRedirectAttrs } = useGetUpgradeModal()
    const isEnterprisePlanBetterValue = useIsEnterprisePlanBetterValue({ planId, seats: values?.seats })
    const isAddingUsers = useGetAddUsersModal()?.showing
    return (
        <Stack spacing={0} align="start">
            <Text fontSize="sm" fontWeight="medium" mt={-2} mb={2.5}>{`How many ${isAddingUsers ? 'additional' : ''} seats do you need?`}</Text>
            <NumberInput value={values?.seats} w="100px" size="sm" min={1} onChange={(val) => setFieldValue("seats", (parseInt(val)))}>
                <NumberInputField rounded="md" />
                <NumberInputStepper>
                    <NumberIncrementStepper fontSize="8px" />
                    <NumberDecrementStepper fontSize="8px" />
                </NumberInputStepper>
            </NumberInput>
            {isEnterprisePlanBetterValue && (
                <Flex
                    mt={2}
                    ml={1}
                    fontSize="sm"
                    color="fg.muted"
                    align="center"
                    gap={1.5}
                >
                    <Button
                        size="sm"
                        variant="link"
                        color="rarchy-link"
                        fontWeight="medium"
                        onClick={() => {
                            if (isAddingUsers) {
                                dispatch(toggleUpgradeModal({ showing: true, planId: 'team', screen, stripeRedirectAttrs }))
                            } else {
                                dispatch(toggleUpgradeModal({ showing: true, planId: 'team', screen, stripeRedirectAttrs }))
                            }
                        }}>
                        {`Rarchy Team `}
                    </Button>
                    {` is better value for ${values?.seats}+ seats`}
                </Flex>
            )}
        </Stack>
    )
}