import { AddIcon, ChatIcon, ChevronRightIcon, CopyIcon, DeleteIcon, EditIcon, ExternalLinkIcon, LinkIcon } from '@chakra-ui/icons'
import { MenuItem as ChakraMenuItem, MenuDivider, Text } from "@chakra-ui/react"
import { PiFile, PiFileBold } from 'react-icons/pi';

import { PageColorScreen } from './Color';
import { PageType } from './Type';
import React from 'react'
import { RiPaintFill } from 'react-icons/ri';
import { Submenu } from './Submenu';
import { VscCollapseAll } from 'react-icons/vsc'
import { forwardRef } from 'react';
import { getInUserFlow } from '../../../../../helpers';
import { toggleUpgradeModal } from '../../../../../store/actions/ui-actions';
import { useDispatch } from 'react-redux';
import { useInUserEditorDoc } from '../../../../../hooks';

export const MenuItem = forwardRef(({ id, icon, text, submenu, onClick, divider }, ref) => {
    const dispatch = useDispatch()
    const inUserEditorDoc = useInUserEditorDoc()
    return (
        <>
            <ChakraMenuItem
                ref={ref}
                icon={icon}
                fontSize="sm"
                command={submenu ? <ChevronRightIcon fontSize="lg" /> : null}
                onClick={(e) => {
                    if (inUserEditorDoc && id === 'color') {
                        dispatch(toggleUpgradeModal({ showing: true, screen: 'page-colors' }))
                    } else {
                        if (onClick) onClick(e, id)
                    }
                }}
                _hover={{ bgColor: 'rarchy-bg-subtle-hover' }}
            >
                {text}
            </ChakraMenuItem>
            {divider && <MenuDivider my={1} />}
        </>
    )
})

const iconFontSize = "1.2em"
const topAdjustment = "0px"

export const createMenuItems = ({ sitemap, canEdit, canComment }) => {
    const { ContextMenuDropdown } = sitemap?.ui || {}
    const { node } = ContextMenuDropdown || {}
    const inUserFlow = getInUserFlow()
    const items = []
    // Add Page
    if (!inUserFlow && (sitemap?.format !== 'nodes' && canEdit)) {
        items.push({
            id: "add",
            text: "Add Page",
            icon: <AddIcon fontSize={iconFontSize} marginTop={topAdjustment} />
        })
    }
    // Delete Page
    if ((sitemap?.format !== 'nodes' && canEdit && (inUserFlow || !inUserFlow && node.parent))) {
        items.push({
            id: "delete",
            text: "Delete Page",
            icon: <DeleteIcon fontSize={iconFontSize} marginTop={topAdjustment} />
        })
    }
    // Rename Page
    const showClonePage = sitemap?.format !== 'nodes' && node?.parent && canEdit
    if (sitemap?.format !== 'nodes' && canEdit) {
        items.push({
            id: "rename",
            text: "Rename Page",
            divider: !showClonePage,
            icon: <EditIcon fontSize={iconFontSize} marginTop={topAdjustment} />
        })
    }
    // Clone Page
    if (showClonePage) {
        items.push({
            id: "clone",
            text: "Clone Page",
            icon: <CopyIcon fontSize={iconFontSize} marginTop={topAdjustment} />
        })
    }
    // Set Page Type
    if (!inUserFlow) items.push({
        submenu: (
            <PageType iconFontSize={iconFontSize} />
        )
    })
    // Set Page Color
    if (!inUserFlow) items.push({
        submenu: <PageColorScreen iconFontSize={iconFontSize} />
    })
    // Edit Page URL
    if (sitemap?.format !== 'nodes' && canEdit) {
        items.push({
            id: "edit-url",
            text: "Edit URL",
            icon: <ExternalLinkIcon fontSize={iconFontSize} marginTop={topAdjustment - 1} />
        })
    }
    // Comments
    if (sitemap?.format !== 'nodes') {
        items.push({
            id: "comments",
            text: sitemap?.comments?.[node?.id] ? "View Comments" : canComment ? "Add Comment" : "View Comments",
            icon: <ChatIcon fontSize="xs" />
        })
    }
    // Subfolders
    if (!inUserFlow && (node?.parent && (node?.children || node?._children))) {
        items.push({
            id: "subfolder",
            text: "View Subdirectory",
            icon: <VscCollapseAll fontSize="1.4em" />
        })
    }
    return items
}