import { getInOrganization, getOrganization } from "../../../../helpers";
import { useHasProcessedSitemapImport, useIsImportingSitemapById } from "../../../../hooks";

import { getShortPlanId } from "../../../../../shared/helpers";

export const useIsSitemapImportCrawl = (i) => i?.type === 'crawl';
export const useDidSitemapImportError = (i) => i?.error ? true : false;
export const useIsSitemapImportPausedByUser = (i) => i?.paused ? true : false;

export const useHasSitemapImportFinishedSuccessfully = (i) => {
    const errored = useDidSitemapImportError(i)
    const isProcessing = useIsImportingSitemapById(i?.id)
    const hasProcessed = useHasProcessedSitemapImport()
    const isImported = !isProcessing || hasProcessed
    if (isImported && !errored) return true;
}

export const getIsImportCountOverLimit = (i) => {
    const { count, limit } = i?.retrieved || {}
    const maxPages = parseInt(i?.options?.maxPages) || 0
    return maxPages === limit ? false : count >= limit
}

export const useIsSitemapImportCompleted = (i = {}) => {
    const finished = useHasSitemapImportFinishedSuccessfully(i)
    const isPausedByUser = useIsSitemapImportPausedByUser(i)
    const isOverLimit = getIsImportCountOverLimit()
    return (finished && !isPausedByUser && !isOverLimit) ? true : false
};

export const useIsSitemapImportIncomplete = (i = {}) => {
    const finished = useHasSitemapImportFinishedSuccessfully(i)
    const isPausedByUser = useIsSitemapImportPausedByUser(i)
    const isOverLimit = getIsImportCountOverLimit(i)
    return (finished && !isPausedByUser && isOverLimit) ? true : false
};

const getCurrentSitemapImportPageLimit = () => {

    const shortPlanId = getShortPlanId()
    const inOrganization = getInOrganization()

    // in free account
    if (!inOrganization) {
        return 50;
    } else { // in organization
        // user is in freelance (legacy) / pro plan
        if (['freelance', 'pro'].includes(shortPlanId)) return 500;
        // user is in team plan
        if (['team', 'enterprise'].includes(shortPlanId)) return 5000;
        // user is in agency / enterprise plan
        if (['agency'].includes(shortPlanId)) return 25000;
    }

    // fallback
    return 50;
}

export const isCurrentImportLimitGreaterThanImported = (limit) => {
    const currentLimit = getCurrentSitemapImportPageLimit();
    return currentLimit > limit
}