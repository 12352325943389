import { Container, DarkMode, Stack } from '@chakra-ui/react'
import { TRIAL_DAYS, useCanStartNewTrial } from '../../../../shared/hooks/pricing'

import { Headline } from '../../-components/Headline'
import { PricingCards } from './PricingCards'
import { isInMarketing } from '../../../../shared/helpers'

const PricingWithTable = () => {
    const canStartNewTrial = useCanStartNewTrial()
    return (
        <DarkMode>
            {isInMarketing && (
                <Container px={{ base: 3, sm: 6 }} pt={{ base: 9, lg: 12 }} pb={{ base: 20, lg: 24 }} bgColor="blue.600" maxW="unset">
                    <Stack spacing={{ base: '4', md: '8' }} marginInline="auto" maxW='7xl' paddingInline={6}>
                        <Stack spacing="3" align="start">
                            <Headline
                                heading="Plans & Pricing"
                                subheading={`Trusted by agencies and teams for large website projects. ${canStartNewTrial ? `Try us for free for ${TRIAL_DAYS} days` : 'Explore which plan is best for you'}.`}
                                maxW="lg"
                                size={{ base: "lg", md: "xl" }}
                                subheadingSize={{ base: "lg", md: "xl" }} spacing={{ base: 6, lg: 10 }}
                                textAlign="start"
                            />
                        </Stack>
                    </Stack>
                </Container>
            )}
            <Container maxW="full" w="full">
                <Container maxW='7xl' paddingInline={{ base: "0rem !important", md: "1rem !important;" }}>
                    <PricingCards alwaysDisplayTable />
                </Container>
            </Container>
        </DarkMode>
    )
}

export default PricingWithTable