import {
    Alert,
    AlertIcon,
    Box,
    Button,
    Center,
    Divider,
    HStack,
    Icon,
    Progress,
    Spinner,
    Square,
    Text,
    VStack,
} from '@chakra-ui/react'
import React, { useState } from 'react'
import { TRIAL_DAYS, useCanStartNewTrial } from '../../../../../../shared/hooks/pricing'
import { useCanFullyEditFile, useGetSitemap, useGetUserId, useInUserEditorDoc } from '../../../../../hooks'

import { AiOutlineCloudUpload } from 'react-icons/ai'
import { CheckCircleIcon } from '@chakra-ui/icons'
import Dropzone from 'react-dropzone'
import { isEmpty } from 'lodash'
import { toggleUpgradeModal } from '../../../../../store/actions/ui-actions'
import { useDispatch } from 'react-redux'
import { useFirebase } from 'react-redux-firebase'

export const Upload = () => {
    const sitemap = useGetSitemap()
    const userId = useGetUserId()
    const firebase = useFirebase()
    const canFullyEdit = useCanFullyEditFile()
    const [uploading, setUploading] = useState({})
    const inUserSitemap = useInUserEditorDoc()
    const isDisabled = inUserSitemap || !canFullyEdit || !isEmpty(uploading)
    return (
        <>
            {inUserSitemap &&<FreeAlert />}
            {!canFullyEdit && <MemberAlert />}
            <VStack spacing={9} mt={6}>
                <Box w="full">
                    <Dropzone
                        acceptedFiles="image/*"
                        noDrag={isDisabled}
                        noClick={isDisabled}
                        maxFiles={1}
                        validator={(file) => {
                            const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
                            if (!isJpgOrPng) {
                                alert('Only a JPG/PNG image can be uploaded');
                                return { code: "not-valid-type" };
                            }
                            //
                            const isLt5M = file.size / 1024 / 1024 < 5;
                            if (!isLt5M) {
                                alert('Image must be smaller than 5MB');
                                return { code: "size-too-large" };
                            }
                            return null;
                        }}
                        onDrop={(acceptedFiles) => {

                            const { page } = sitemap.ui.PageDrawer;
                            const { device } = sitemap.covers;

                            const file = acceptedFiles[0];

                            setUploading({ name: file.name, progress: 0 })

                            // upload cover
                            var uploadTask = firebase.storage().ref().child(`sitemaps/${sitemap.id}/covers/${page.id}/${device}/cover.jpg`).put(file, { customMetadata: { sitemapId: sitemap.id, userId } });

                            uploadTask.on(
                                'state_changed',
                                snapshot => {
                                    var percent = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100)
                                    percent = percent < 1 ? 1 : percent
                                    setUploading({ name: file.name, progress: percent < 100 ? percent : 99 })
                                },
                                error => {
                                    console.log(error)
                                },
                                async () => {

                                    /*** CREATE THUMBNAIL ***/
                                    const arrayBuffer = await file.arrayBuffer();
                                    // Read ArrayBuffer with Jimp ("any" to avoid type errors)
                                    // eslint-disable-next-line
                                    const thumb = await Jimp.read(arrayBuffer);
                                    // eslint-disable-next-line
                                    thumb.cover(500, 500, Jimp.HORIZONTAL_ALIGN_CENTER | Jimp.VERTICAL_ALIGN_TOP);
                                    // eslint-disable-next-line
                                    const thumbBuffer = await thumb.getBufferAsync(Jimp.MIME_PNG);

                                    // thumbnail ref
                                    const thumbRef = firebase.storage().ref().child(`sitemaps/${sitemap.id}/covers/${page.id}/${device}/thumb.jpg`);

                                    // upload thumbnail
                                    await thumbRef.put(thumbBuffer, {
                                        cacheControl: 'max-age=31557600',
                                        contentType: 'image/jpg',
                                        customMetadata: {
                                            sitemapId: sitemap.id,
                                        }
                                    });

                                    await firebase.functions().httpsCallable('sitemaps-covers-updateThumbDownloadToken')({ sitemapId: sitemap.id, pageId: page.id, device });
                                    /*** CREATE THUMBNAIL ***/

                                    setUploading({ name: file.name, progress: 100 }) // set to success

                                    setUploading({}) // clear uploading

                                }, 1500);

                        }}>
                        {({ getRootProps, getInputProps, isDragActive }) => (
                            <>
                                <Center
                                    rounded="md"
                                    borderWidth="1px"
                                    borderColor="rarchy-border"
                                    _hover={{ cursor: isDisabled ? 'not-allowed' : 'pointer' }}
                                    boxShadow="xs"
                                    p={6}
                                    py={20}
                                    bg={isDragActive ? "rarchy-bg-subtle" : "rarchy-bg-sitemap-editor"}
                                    {...getRootProps()}
                                >
                                    <input {...getInputProps()} />
                                    <VStack spacing="4">
                                        <Square size="20" rounded="lg">
                                            <Icon color="fg.subtle" as={AiOutlineCloudUpload} boxSize="20" />
                                        </Square>
                                        <VStack spacing="6">
                                            <HStack spacing="1" whiteSpace="nowrap">
                                                {!isDragActive && <Button variant="link" colorScheme="blue" size="md" isDisabled={inUserSitemap}>
                                                    Click to upload
                                                </Button>}
                                                <Text>
                                                    {isDragActive ? 'Drop image to upload' : 'or drag image to this area'}
                                                </Text>
                                            </HStack>
                                            <Text fontSize="sm" color="fg.subtle">
                                                JPG/PNG files only. 5MB max file size.
                                            </Text>
                                        </VStack>
                                    </VStack>
                                </Center>
                                {!isEmpty(uploading) && (
                                    <>
                                        <Divider />
                                        <HStack spacing={4} p={2}>
                                            {uploading.progress < 100 && <Spinner size="sm" color={uploading.progress === 100 ? 'green' : "rarchy-link"} />}
                                            {uploading.progress === 100 && <CheckCircleIcon color="green.500" />}
                                            <VStack w="full" alignItems="start">
                                                <Text noOfLines={1} fontSize="13px">{uploading.name}</Text>
                                                <Progress w="full" rounded="md" colorScheme={uploading.progress === 100 ? 'green' : 'twitter'} size='xs' value={uploading.progress} />
                                            </VStack>
                                        </HStack>
                                    </>
                                )}
                            </>
                        )}
                    </Dropzone>
                </Box>
            </VStack>
        </>
    )
}

const FreeAlert = () => {
    const dispatch = useDispatch()
    const canStartNewTrial = useCanStartNewTrial()
    return (
        <Alert status='info' px={4} py={4} mt={3} variant="info" alignItems="flex-start" fontWeight="normal">
            <AlertIcon color="gray.500" />
            <VStack align="start" ml={1}>
                <Box>
                    Image uploads are only available in our paid plans.
                </Box>
                <Box>
                    <Button size="sm" variant="link" color="rarchy-link" fontWeight="medium" mr={1.5} onClick={() => {
                        dispatch(toggleUpgradeModal({ showing: true, planId: 'team', screen: 'plans-pricing' }))
                    }}>
                        {canStartNewTrial ? `Try Rarchy Team for free for ${TRIAL_DAYS} days` : `Upgrade to Rarchy Team`}
                    </Button>
                </Box>
            </VStack>
        </Alert>
    )
}

const MemberAlert = () => {
    return (
        <Alert status='info' px={4} py={4} mt={3} variant="info" alignItems="flex-start" fontWeight="normal">
            <AlertIcon color="gray.500" />
            <Box>
                Only team members can upload images.
            </Box>
        </Alert>
    )
}