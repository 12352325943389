import {
    Box,
    Button,
    Link,
    PopoverTrigger,
    Text,
    Tooltip,
    useColorModeValue as mode
} from "@chakra-ui/react"
import { IoCloudDoneOutline, IoCloudOfflineOutline } from 'react-icons/io5'

import { HiOutlineRefresh } from 'react-icons/hi'
import React from 'react'
import { toggleRevisionHistoryDrawer } from '../../../../../../store/actions/editor-actions'
import { toggleUpgradeModal } from '../../../../../../store/actions/ui-actions'
import { useDispatch } from 'react-redux'
import { useIsUserFile } from '../../../../../../hooks'

export const TriggerButton = ({ canEdit, online, sitemap, saving, saved, stopped }) => {
    const dispatch = useDispatch()
    const inUserFile = useIsUserFile()
    const text = renderText(online, saving, saved)
    const pie = !text ? 0 : 2
    return (
        <Box minW="90px" lineHeight={1.2}>
            {canEdit && (
                <PopoverTrigger>
                    <Button size="sm" variant="ghost" fontSize="lg" leftIcon={renderIcon({ canEdit, online, sitemap, saving, stopped })} iconSpacing={3} paddingInlineEnd={pie}>
                        <Text fontSize="xs" fontWeight={500}>{text}</Text>
                        {!text && canEdit && (
                            <Tooltip variant="rounded-sm" hasArrow label="Open Revision History" openDelay={1000}>
                                <Link
                                    mr={4}
                                    fontSize="xs"
                                    color={canEdit ? "unset" : "rarchy-red"}
                                    fontWeight="medium"
                                    onClick={() => {
                                        if (inUserFile) {
                                            dispatch(toggleUpgradeModal({ showing: true, planId: 'team', screen: 'revision-history' }))
                                        } else {
                                            dispatch(toggleRevisionHistoryDrawer())
                                        }
                                    }}>
                                    History
                                </Link>
                            </Tooltip>
                        )}
                    </Button>
                </PopoverTrigger>
            )}
        </Box>
    )
}

const renderIcon = ({ canEdit, online, sitemap, saving, stopped }) => {
    if (!canEdit || !online || stopped) return <IoCloudOfflineOutline color="rarchy-red" fontSize="17px" />
    if (saving) return <HiOutlineRefresh fontSize="15px" />
    return <IoCloudDoneOutline fontSize="18px" />
}

const renderText = (online, saving, saved) => {
    if (!online) return 'Offline'
    if (saving) return 'Saving...'
    if (saved) return 'Saved'
    return null;
}