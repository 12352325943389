import { Avatar, Box, Button, Stack, Text, useBoolean, useDisclosure } from "@chakra-ui/react"
import { BorderedBox, MainHeader } from "./Components"
import { SkeletonCircleWrapper, SkeletonWrapper } from "../Components/Shared/SkeletonWrapper"
import { getInProPlan, getShortPlanId } from "../../../shared/helpers"
import { useGetOrganizationsData, useGetUser, useGetUserId, useHasFoldersLoaded } from "../../hooks"

import { DeleteModal } from "./Organization/Details/Delete/Modal"
import { getFirebase } from "react-redux-firebase"
import { isEmpty } from 'lodash'
import { useState } from "react"

// used for loading
const DUMMY_ORGANIZATIONS = [{ id: 'test', name: 'Organization', createdBy: 'userId', users: { ['test']: { role: 'admin' } } }]

export const Accounts = () => {
    const [selectedOrganization, setSelectedOrganization] = useState()
    const [isRestoring, setIsRestoring] = useState()
    const { isOpen, onToggle, onClose } = useDisclosure()
    const organizations = useGetOrganizationsData()
    const archived = useGetOrganizationsData({ onlyArchived: true })
    const hasLoadedFolders = useHasFoldersLoaded()
    const user = useGetUser()
    const userId = useGetUserId()
    return (
        <Stack spacing={10} pb={12}>
            <Stack w="3xl" spacing={5}>
                <MainHeader title="Your organizations" />
                <Stack spacing={3}>
                    {isEmpty(organizations) && hasLoadedFolders && <Box color="fg.muted" fontSize="15px">You aren't a member of any organization accounts</Box>}
                    {(isEmpty(organizations) ? (hasLoadedFolders ? [] : DUMMY_ORGANIZATIONS) : organizations)?.map(o => {
                        const owner = o?.createdBy === userId
                        const isAdmin = o?.users?.[userId]?.role === 'admin'
                        const membersCount = Object.keys(o?.users || [])?.length
                        return (
                            <BorderedBox key={o?.id}>
                                <Stack direction="row" justifyContent="space-between">
                                    <Stack direction="row" spacing={8}>
                                        <SkeletonCircleWrapper w="40px" h="40px">
                                            <Avatar name={o?.name} size="smd" />
                                        </SkeletonCircleWrapper>
                                        <Stack>
                                            <MainHeader title={o?.name} />
                                            <Box fontSize="xs">
                                                <SkeletonWrapper w="fit-content">
                                                    {owner ? 'Owner' : 'Member'}
                                                    <Text display="inline" mx={1.5} fontSize="8px">|</Text>
                                                    {`${membersCount} member${membersCount > 1 ? 's' : ''}`}
                                                </SkeletonWrapper>
                                            </Box>
                                        </Stack>
                                    </Stack>
                                    {isAdmin && (
                                        <SkeletonWrapper w="fit-content">
                                            <Button
                                                fontSize="sm"
                                                px={6}
                                                onClick={() => {
                                                    setSelectedOrganization(o)
                                                    onToggle()
                                                }}>
                                                Delete
                                            </Button>
                                        </SkeletonWrapper>
                                    )}
                                </Stack>
                            </BorderedBox>
                        )
                    })}
                </Stack>
                <DeleteModal organization={selectedOrganization} isOpen={isOpen} onClose={onClose} />
            </Stack>
            <Stack w="3xl" spacing={5}>
                <MainHeader mt={5} title="Archived organizations" />
                {isEmpty(archived) && hasLoadedFolders && <Box color="fg.muted" fontSize="15px">You aren't a member of any archived organizations</Box>}
                {!isEmpty(archived) && (
                    <Stack>
                        {archived?.map(o => {
                            const owner = o?.createdBy === userId
                            const membersCount = Object.keys(o?.users)?.length
                            return (
                                <BorderedBox key={o?.id}>
                                    <Stack direction="row" justifyContent="space-between">
                                        <Stack direction="row" spacing={8}>
                                            <SkeletonCircleWrapper w="40px" h="40px">
                                                <Avatar name={o?.name} size="smd" />
                                            </SkeletonCircleWrapper>
                                            <Stack>
                                                <MainHeader title={o?.name} />
                                                <SkeletonWrapper w="fit-content">
                                                    <Box fontSize="xs">
                                                        {owner ? 'Owner' : 'Member'}
                                                        <Text display="inline" mx={1.5} fontSize="8px">|</Text>
                                                        {`${membersCount} member${membersCount > 1 ? 's' : ''}`}
                                                    </Box>
                                                </SkeletonWrapper>
                                            </Stack>
                                        </Stack>
                                        <SkeletonWrapper w="fit-content">
                                            <Button
                                                fontSize="sm"
                                                px={6}
                                                isLoading={isRestoring === o?.id}
                                                onClick={async () => {

                                                    try {

                                                        setIsRestoring(o?.id);

                                                        const firebase = getFirebase()

                                                        const oldPlanId = getShortPlanId(o?.subscription?.plan?.id)
                                                        const planId = getInProPlan(oldPlanId) ? 'pro' : 'team'

                                                        const billingInterval = o?.subscription?.plan?.interval;

                                                        const { origin, pathname } = window.location || {}

                                                        const sessionURL = await firebase.functions().httpsCallable('stripe-createCheckoutSession')({
                                                            referrerURL: `${origin}${pathname}`,
                                                            planId,
                                                            email: user?.email,
                                                            metadata: {
                                                                orgId: o.id,
                                                                orgName: o.name,
                                                                orgWebsite: o.domain,
                                                                billingInterval,
                                                                seats: membersCount,
                                                                user: user?.id,
                                                                firstName: user?.firstName,
                                                                lastName: user?.lastName,
                                                                // immediatelySub: false // no trial
                                                            }
                                                        });

                                                        if (sessionURL?.data) window.location = sessionURL.data;
                                                    } catch (e) {

                                                    } finally {
                                                        setIsRestoring()
                                                    }
                                                }}>
                                                Restore
                                            </Button>
                                        </SkeletonWrapper>
                                    </Stack>
                                </BorderedBox>
                            )
                        })}
                    </Stack>
                )}
            </Stack>
        </Stack>
    )
}