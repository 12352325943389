import { applyYWithCavasOffset, getPageColor, getPageId, getRandomColor, getX, getY } from '../../utils/helpers';
import { endRenamePageSection, renamePageSection } from '../text/utils';
import { getCanvasTextEditor, getEditor, getInUserFlow, getSitemap, getUserFlow } from '../../../../../../helpers';
import { getWireframePalletteColors, getWireframeWithColor } from '../../utils/page-sections/wireframe';
import { isEmpty, orderBy } from 'lodash'

import canvasTxt from '../text/canvasTxt';
import copy from 'fast-copy';
import { dragging } from '../../utils/drag';
import { event } from 'd3';
import { insertNewPageSection } from '../../utils/page-sections/new';
import { parse } from 'svg-parser';
import { reorderPageSections } from '../../utils/page-sections/reorder';
import { showEditorPermissionsToast } from '../../../../../Editor/App/Components/PermissionsToast';
import { store } from '../../../../../../store'
import { togglePageSectionsOptionsPopover } from '../../../../../../store/actions/sitemap-actions';
import { transform } from '../../utils/zoom';
import wireframeSections from './options/Wireframes/modules';

var colors = {};
const colorByNodeIds = {};

var font = '600 15px Inter,sans-serif';

export const sectionWidth = 225 - 35; // 28;

export const renderPageSections = async (context, d, hidden, opts = {}) => {

    const { forThumbnail } = opts

    const { flow, ui } = store.getState()
    const sitemap = getSitemap()
    const CanvasTextEditor = getCanvasTextEditor()

    const inUserFlow = getInUserFlow() && (d.type && d?.type?.includes('page-sections'));
    const showCovers = inUserFlow ? false : sitemap?.showCovers;
    if (showCovers) return;

    if (inUserFlow && dragging) {
        if (context.canvas.id !== 'interactions-canvas') {
            const multi = flow.data.nodes.filter(d => d._selected).length > 1;
            if (multi) return;
        }
    }

    const sectionPageId = (d.dragging && d.section ? d.node.id : d.id);

    let sectionData = !inUserFlow ? (sitemap?.data?.page_sections?.[sectionPageId] || []) : d.sections;
    if (forThumbnail) sectionData = structuredClone(sectionData)

    /*** background ***/
    if (!hidden) {
        context.fillStyle = ui.colorMode === 'light' ? 'white' : '#1A202C';
        roundRect(context, getX(d) + 1, getY(d) + 1, 225 - 2, d.nodeHeight - 2, 4, hidden, true /* bg */);
    }
    /*** background ***/

    if (!isEmpty(sectionData)) {

        /*** dragging section ***/
        if (context.canvas.id === 'interactions-canvas') {
            if (context.globalAlpha === 0.99) return // stops flickering when dragging
            if (d.dragging && d.section) { // d = dragging
                context.globalAlpha = 0.99;
                const draggingY = draggingYWithBoundary(d);
                /*** rect ***/
                context.fillStyle = ui.colorMode === 'light' ? 'white' : '#1A202C';
                context.strokeStyle = d.section.wColors[5];
                roundRect(context, d.section.x, draggingY, sectionWidth, d.section.rectHeight, 3, hidden);
                document.getElementById('interactions-canvas').style.cursor = "grabbing";
                /*** rect ***/
                /*** text ***/
                if (d.section.title) {
                    canvasTxt.fontSize = 15;
                    canvasTxt.align = 'left';
                    canvasTxt.vAlign = 'middle';
                    canvasTxt.fontWeight = 500;
                    canvasTxt.font = 'Inter,sans-serif';
                    canvasTxt.lineHeight = 17;
                    context.font = font;
                    context.fillStyle = d.section.tColor;
                    canvasTxt.drawText(context, d.section.title, d.section.tx, getTextY(draggingY, d.section), (sectionWidth - 21.5), 17);
                };
                /*** text ***/
                /*** wireframe ***/
                if (d.section.wireframe) renderWireframe(context, { node: d.node, ...d.section, wy: getWireframeY(draggingY + d.section.height, d.section) });
                /*** wireframe ***/
                context.globalAlpha = 1;
                return;
            }
        }
        /*** dragging section ***/

        orderBy(sectionData, ['index']).forEach(async (section, i) => {

            const draggingSection = d.dragging && (d.section && d.section.id === section.id);
            if (draggingSection) return;

            if (context.canvas.id !== 'interactions-canvas') {
                if (dragging && dragging.section) {
                    if (dragging.section.id === section.id) return;
                }
            };

            /*** assign event colors ***/
            var randomColor;
            if (hidden) {
                if (!colorByNodeIds[d.id] || !colorByNodeIds[d.id][section.id]) {
                    /*** assign new random color to node id ***/
                    randomColor = getRandomColor();
                    colorByNodeIds[d.id] = { ...colorByNodeIds[d.id], [section.id]: randomColor };
                    colors[randomColor] = { node: d, section };
                    /*** assign new random color to node id ***/
                } else {
                    randomColor = colorByNodeIds[d.id][section.id]; // use existing assigned color
                }
            };
            /*** assign event colors ***/

            /*** rect ***/
            if (section.wColors) context.fillStyle = hidden ? randomColor : 'transparent' // section.wColors[0];
            if (section.wColors) context.strokeStyle = hidden ? randomColor : section.wColors[6];
            roundRect(context, forThumbnail ? getSectionX(getX(d)) : section.x, section.y, sectionWidth, (section.rectHeight + (inUserFlow && hidden && i !== sectionData.length - 1 ? 10 : 0)), 3, hidden);
            /*** rect ***/

            /*** text ***/
            const isRenaming = (CanvasTextEditor.section && CanvasTextEditor.section.id === section.id)// && context.canvas.id === 'interactions-canvas'
            if (section.title && !hidden && !isRenaming) {

                /* if (RevisionHistoryDrawer.showing) {
                    const change = RevisionHistoryDrawer.changes[RevisionHistoryDrawer.selectedIndex];
                    if (change.doc === 'sections') {
                        Object.keys(change.delta).forEach(k => {
                            if (k === d.id) {
                                const c = change.delta[k];
                                Object.keys(c).forEach(i => {
                                    const s = c[i];
                                    if (i === section.id) {
                                        if (s.title) {
                                            context.fillStyle = 'yellow';
                                            context.globalAlpha = 0.25;
                                            roundRect(context, section.x + 5, section.y + 5, sectionWidth - 10, section.rectHeight - 10, 3, true /* no stroke *//*);
context.globalAlpha = 1;
}
}
})
}
})
}
} */

                canvasTxt.fontSize = 15;
                canvasTxt.align = 'left';
                canvasTxt.vAlign = 'middle';
                canvasTxt.fontWeight = 500;
                canvasTxt.font = 'Inter,sans-serif';
                canvasTxt.lineHeight = 17;
                context.font = font;
                context.fillStyle = section.tColor;
                canvasTxt.drawText(context, section.title, forThumbnail ? getSectionTextX(getSectionX(getX(d))) : section.tx, section.ty, (sectionWidth - 21.5), 17);
            };
            /*** text ***/

            /*** wireframe ***/
            if (!hidden && section.wireframe) {
                await renderWireframe(context, { node: d, ...section, wx: forThumbnail ? getSectionX(getX(d)) : section.wx });
                /* if (RevisionHistoryDrawer.showing) {
                    const change = RevisionHistoryDrawer.changes[RevisionHistoryDrawer.selectedIndex];
                    if (change.doc === 'sections') {
                        Object.keys(change.delta).forEach(k => {
                            if (k === d.id) {
                                const c = change.delta[k];
                                Object.keys(c).forEach(i => {
                                    const s = c[i];
                                    if (i === section.id) {
                                        if (s.wireframe) {
                                            context.fillStyle = 'yellow';
                                            context.globalAlpha = 0.25;
                                            roundRect(context, blah.x, blah.y, blah.width, blah.height, 3, true /* no stroke *//*);
context.globalAlpha = 1;
}
}
})
}
})
}
} */
            }
            /*** wireframe ***/

            // highlight whole section in revision history drawer
            /* if (RevisionHistoryDrawer.showing) {
                const change = RevisionHistoryDrawer.changes[RevisionHistoryDrawer.selectedIndex];
                if (change.doc === 'sections') {
                    Object.keys(change.delta).forEach(k => {
                        if (k === d.id) {
                            const c = change.delta[k];
                            Object.keys(c).forEach(i => {
                                const s = c[i];
                                if (i === section.id) {
                                    if (!s.title && !s.wireframe) {
                                        context.fillStyle = 'yellow';
                                        context.globalAlpha = 0.25;
                                        roundRect(context, section.x, section.y, sectionWidth, section.rectHeight, 3, hidden);
                                        context.globalAlpha = 1;
                                    }
                                }
                            })
                        }
                    })
                }
            } */

        });
    }
};

const draggingYWithBoundary = (d) => {

    const { sitemap } = store.getState()

    var nodeY = (sitemap?.format === 'tree' || sitemap?.format === 'indent') ? d.node.x : d.node.y;

    const upperBounds = nodeY + d.node.textRectHeight + 15;
    const lowerBounds = nodeY + d.node.bottomOfNode - (d.section.height + 9);
    const y = d.section.dy - (dragging.section.rectHeight / 2);

    const pageSections = { ...sitemap?.data.page_sections };
    reorderPageSections(d, pageSections);

    if (y <= upperBounds) return upperBounds;
    if (y >= lowerBounds) return lowerBounds;

    return y;
};

const getTextY = (y, section) => y + (section.textHeight / 2) - 3.5;

const getWireframeY = (bottomOfSectionFromY, section) => bottomOfSectionFromY - ((section.wireframesHeight * 0.95) + 13.5);

// eslint-disable-next-line
Array.prototype.move = function (from, to) {
    this.splice(to, 0, this.splice(from, 1)[0]);
    return this;
};

// eslint-disable-next-line
Number.prototype.between = function (a, b) {
    var min = Math.min.apply(Math, [a, b]),
        max = Math.max.apply(Math, [a, b]);
    return this > min && this < max;
};

const SPACING = 10;

export const getPageSectionsHeight = (d) => {

    const sitemap = getSitemap()

    const inUserFlow = getInUserFlow();
    const showCovers = inUserFlow ? d.type === 'page' ? true : false : sitemap?.showCovers;

    if (showCovers) return 0;

    const nodeId = d.id === 'dragging-placeholder' ? dragging.id : getPageId(d)

    const sectionData = sitemap?.data.page_sections ? sitemap?.data.page_sections[nodeId] ? sitemap?.data.page_sections[nodeId] : [] : [];

    const defaultHeight = 31;
    if (isEmpty(sectionData)) return defaultHeight + 26; // create space for new click

    var totalHeight = 6; // start at for bottom margin;
    orderBy(sectionData, ['index']).forEach((s, i) => {
        var height = s.height ? s.height : getPageSectionHeights(s).height;
        totalHeight += height ? height : defaultHeight;
    });

    // return
    return totalHeight + SPACING;
};

export const getPageSectionHeights = (s) => {
    var textHeight = getPageSectionTextHeight(s);
    var wireframesHeight = getPageSectionWireframesWidthHeight(s).height + 2.5; // 2.5 = padding-bottom of wireframe;
    var rectHeight = textHeight + wireframesHeight + (!s.wireframe ? 11.5 : 17); // + = padding-top of section
    var height = rectHeight + SPACING; // + = spacing between sections
    return { textHeight, wireframesHeight, rectHeight, height };
}

export const setSectionAttrs = ({ nodes, forThumbnail }) => {

    const CanvasTextEditor = getCanvasTextEditor()
    const sitemap = getSitemap()

    const { PageButtons } = sitemap?.ui || {}

    const renaming = CanvasTextEditor.showing ? CanvasTextEditor : null;

    nodes.forEach((d, i) => {

        const inUserFlow = d.page && (d.type && d?.type?.includes('page-sections'));
        const showCovers = inUserFlow ? false : sitemap?.showCovers;
        if (showCovers) return d;

        const pageId = getPageId(d);

        let sectionData = sitemap?.data?.page_sections?.[pageId] || [];

        if (forThumbnail) sectionData = structuredClone(sectionData)

        const editingInUserFlowPage = PageButtons?.node?.page === d.id;
        const node = editingInUserFlowPage ? PageButtons.node : d;

        if (!isEmpty(sectionData)) {
            var header = 15;
            const sortedSectionData = orderBy(sectionData, ['index']);
            var startingY = 10; // has to be here otherwise x/y will be wrong if editing from user flow
            sortedSectionData.map((s, i) => {
                const oldTitle = s.title; // grab this before updating attrs (so we can ensure we save title change only when changed)
                // renaming
                if (renaming && renaming.section) {
                    if (renaming.section.id === s.id) s.title = renaming.title;
                }
                // do heights first
                const heights = getPageSectionHeights(s);
                s.textHeight = heights.textHeight;
                s.wireframesHeight = heights.wireframesHeight;
                s.rectHeight = heights.rectHeight;
                s.height = heights.height;
                // coords
                var sectionX = getSectionX((getX(s.new ? node : d)))
                if (i === 0) startingY = startingY + getY(s.new ? node : d) + (header + d.textRectHeight); // has to be here otherwise x/y will be wrong if editing from user flow
                s.x = sectionX;
                s.y = startingY += i === 0 ? 0 : sortedSectionData[i - 1].height + 0;
                /*** setting heights ***/
                s.bottomOfSectionFromY = s.y + s.height;
                /*** setting heights ***/
                // set text coords
                s.tx = getSectionTextX(s.x)
                s.ty = getTextY(s.y, s);
                // set wireframe coords
                if (s.wireframe) {
                    s.wx = s.x;
                    s.wy = getWireframeY(s.bottomOfSectionFromY, s);
                }
                // set wireframe + text colors
                s.wColors = getWireframePalletteColors({ ...s, node: d });
                s.tColor = s.wColors[7];
                s.title = oldTitle;
                /*** run updates (for new sections etc) ***/
                if (s.new) {
                    // delete new attr on s
                    delete s.new;
                    renamePageSection(node, s); // rename section immediately
                    // show page section options
                    showPageSectionsOptions({ node, section: s, opts: { renaming: true } }); // show page options 
                }
                /*** run updates (for new sections etc) ***/
                return s;
            });
            // user flows only (so we can have multiple of the same page in one user flow) // NEEDS TO BE COPIED
            if (inUserFlow) d.sections = copy(sortedSectionData);
        }
    });

    return nodes;
}

export const getSectionX = x => x + 17.5
export const getSectionTextX = x => x + 9

export const getPageSectionTextHeight = (section) => {

    // need to change this so it doesn't fire on every render (like we have with page text) - this means heights obj

    const CanvasTextEditor = getCanvasTextEditor()

    const renamingPage = CanvasTextEditor.showing && CanvasTextEditor.section && CanvasTextEditor.section.id === section.id;
    var title = renamingPage ? CanvasTextEditor.newString : section.title ? section.title : '';

    var fakeContext = document.createElement('canvas').getContext("2d");
    canvasTxt.fontSize = 15;
    canvasTxt.align = 'left';
    canvasTxt.vAlign = 'middle';
    canvasTxt.fontWeight = 400;
    canvasTxt.lineHeight = 17;
    canvasTxt.font = 'Inter,sans-serif';
    const { height } = canvasTxt.drawText(fakeContext, title, 0, 0, (sectionWidth - 18), 17);
    return height;
};

export const getPageSectionWireframesWidthHeight = (section) => {
    if (!section.wireframe) return { width: 0, height: 0 };
    var wireframe = wireframeSections[section.wireframe], width, height;
    if (!wireframe.height) {
        const { properties } = parse(wireframe.svg).children[0];
        width = wireframe.width = properties.width;
        height = wireframe.height = properties.height;
    } else {
        width = wireframe.width;
        height = wireframe.height;
    };
    return { width, height };
}

export const mouseoverSection = (colKey) => {
    var data = typeof colors[colKey] === 'object' ? colors[colKey] : {};
    let { node, section } = data;
    if (node && section) {
        const { editor, sitemap, flow } = store.getState();
        if (editor?.ui.RevisionHistoryDrawer.showing) return // no mouseover in revision history
        const inUserFlow = getInUserFlow()
        const nodeId = node?.id;
        // get most up to date node data
        const nodes = !inUserFlow ? sitemap?.data.nodes : flow.data.nodes
        node = nodes.find(d => d.id === nodeId) // get most up to date node data
        // get most up to date section data - IMPORTANT OR THIS USES OLD DATA WHEN DRAGGING
        if (!inUserFlow) {
            if (sitemap?.data.page_sections[nodeId] && section.id !== 'new') section = sitemap?.data.page_sections[nodeId].find(s => s.id === section.id);
        } else {
            section = node.sections.find(s => s.id === section.id)
        }
        return { node, section };
    }
};

export const clickPageSection = (colKey) => {

    var data = typeof colors[colKey] === 'object' ? colors[colKey] : {};
    var { node, section } = data;

    const editor = getEditor()
    const sitemap = getSitemap()
    const flow = getUserFlow()

    const inUserFlow = getInUserFlow();
    // get most up to date node data
    const nodeId = node?.id;
    const nodes = !inUserFlow ? sitemap?.data.nodes : flow.data.nodes
    node = nodes.find(d => d.id === nodeId) // get most up to date node data
    // continue
    if (section) {
        if (editor?.ui?.RevisionHistoryDrawer?.showing) return // no clicking in revision history
        // stop click propagating
        event.preventDefault();
        event.stopImmediatePropagation();
        // new
        if (section?.id === 'new') return insertNewPageSection({ nodeId, index: 0 });
        const inUserFlow = getInUserFlow()
        // get most up to date section data - IMPORTANT OR THIS USES OUT OF DATA SECTION AFTER UNDOING / REDOING  (don't need to do this in user flows, otherwise sectionattrs get overriden)
        if (!inUserFlow) {
            if (sitemap?.data?.page_sections?.[nodeId] && section?.id !== 'new') section = sitemap?.data.page_sections[nodeId].find(s => s.id === section?.id);
        } else {
            section = node.sections.find(s => s.id === section.id)
        }
        //
        const { showing } = sitemap?.ui?.PageSectionsOptionsPopover;
        const isSameSection = sitemap?.ui.PageSectionsOptionsPopover?.section?.id === section?.id;
        if (showing && isSameSection) {
            renamePageSection(node, section);
        } else {
            showPageSectionsOptions({ node, section });
        }
    }
};

const showPageSectionsOptions = ({ node, section, opts }) => {

    const sitemap = getSitemap()
    const CanvasTextEditor = getCanvasTextEditor()

    opts = opts || {}; // fail-safe
    const { x, y } = getCanvasBoundingClientRect(section);
    const right = (x + ((194.5 / 2) * transform.k)), top = y - 44;
    // ensure renaming is ended for previously editing section
    if (opts.renaming) endRenamePageSection(CanvasTextEditor.node, CanvasTextEditor.section, CanvasTextEditor.newString)
    // toggle popover if user has edit permissions
    // can edit
    const canEdit = showEditorPermissionsToast({ doc: sitemap });
    if (!canEdit) return;
    // continue
    store.dispatch(togglePageSectionsOptionsPopover({ showing: true, node, section, offset: [right, top] }));
}

const getCanvasBoundingClientRect = (d) => {
    const sitemap = getSitemap()
    const inUserFlow = getInUserFlow()
    var sectionX = (!inUserFlow && sitemap?.format === 'tree') ? getY(d) : getX(d);
    const x = transform.applyX(sectionX);
    const y = applyYWithCavasOffset(d?.y)
    return { x, y };
};

function roundRect(ctx, x, y, width, height, radius, hidden, background) {
    if (width < 2 * radius) radius = width / 2;
    if (height < 2 * radius) radius = height / 2;
    ctx.beginPath();
    ctx.moveTo(x + radius, y);
    ctx.arcTo(x + width, y, x + width, y + height, radius);
    ctx.arcTo(x + width, y + height, x, y + height, radius);
    ctx.arcTo(x, y + height, x, y, radius);
    ctx.arcTo(x, y, x + width, y, radius);
    ctx.closePath();
    ctx.lineWidth = 1.5;
    ctx.fill();
    if (!hidden && !background) ctx.stroke();
};

export const getSectionColor = (section) => {
    if (section?.color) return section.color;
    let pageColor = getPageColor(section?.node, { forWireframe: true });
    return pageColor;
};

export const loaded_wireframes = {};
export const renderWireframe = async (context, section) => {

    let { wx, wy } = section;

    const wireframe = wireframeSections[section?.wireframe];
    // get width and height
    var width = wireframe.width * 0.9, height = wireframe.height * 0.9;
    // update wx
    wx = wx + wireframe.width * 0.085;
    // ensure loading and loaded objects are setup
    if (isEmpty(loaded_wireframes[section.id])) loaded_wireframes[section.id] = {};
    // have to add dragging check into loaded_wireframes in order to stop flicker on initial page drag
    if (loaded_wireframes[section.id][section.wireframe]) {
        if (dragging && (dragging.id === section.node.id) && !loaded_wireframes[section.id][section.wireframe].dragging) return loaded_wireframes[section.id][section.wireframe].dragging = true;
    }
    // account for modified loaded y
    if (loaded_wireframes?.[section.id]?.[section.wireframe]) {
        if (loaded_wireframes?.[section.id]?.[section.wireframe]?.y !== wy) {
            if (!dragging) {
                delete loaded_wireframes[section.id][section.wireframe];
            } else {
                loaded_wireframes[section.id][section.wireframe] = { dragging: true }
            }
        }
    }
    // account for refreshed section color
    if (loaded_wireframes[section.id][section.wireframe] && (loaded_wireframes[section.id][section.wireframe].color !== getSectionColor(section))) {
        if (!dragging) {
            delete loaded_wireframes[section.id][section.wireframe];
        } else {
            loaded_wireframes[section.id][section.wireframe] = { dragging: true }
        }
    }
    /*** already loaded wireframe / image ***/
    if (loaded_wireframes[section.id][section.wireframe]) {
        if (loaded_wireframes[section.id][section.wireframe].base_image) {
            const { base_image, y } = loaded_wireframes[section.id][section.wireframe];
            context.drawImage(base_image, wx, y, width, height);
            return { x: Math.floor(transform.x + wx * transform.k), y: Math.floor(transform.y + wy * transform.k), width: Math.floor(width * transform.k), height: Math.floor(height * transform.k) };
        }
    }
    /*** already loaded wireframe / image ***/

    /*** load wireframe / image ***/
    if (!loaded_wireframes[section.id][section.wireframe] || (loaded_wireframes[section.id][section.wireframe] && !loaded_wireframes[section.id][section.wireframe].base_image)) {
        var image;
        const base_image = new Image();
        image = { src: "data:image/svg+xml;base64," + btoa(getWireframeWithColor(section, { forWireframe: true })) };
        base_image.src = image.src;
        // load wireframe
        await base_image.decode();
        loaded_wireframes[section.id][section.wireframe] = { ...loaded_wireframes[section.id][section.wireframe], base_image, color: getSectionColor(section), y: wy };
        // eslint-disable-next-line
        context.drawImage(loaded_wireframes[section.id][section.wireframe].base_image, transform.x + wx * transform.k, transform.y + wy * transform.k, width * transform.k, height * transform.k);
    }
    /*** load wireframe / image ***/
    return { x: transform.x + wx * transform.k, y: transform.y + wy * transform.k, width: width * transform.k, height: height * transform.k };
}