import { Box, Button, Flex, Heading, Icon, Slider, SliderFilledTrack, SliderThumb, SliderTrack, Stack, Text, Tooltip, VStack } from "@chakra-ui/react";
import { useInAgencyPlan, useInEnterprisePlan, useInOrganization, useInProPlan } from "../../../../hooks";

import { BsQuestionCircle } from "react-icons/bs";
import { UpgradePopover } from "./Status/UpgradePopover";
import { useDispatch } from "react-redux";
import { useFormikContext } from "formik";
import { useState } from "react";

export const MaxPages = ({ isCrawl }) => {
    const dispatch = useDispatch()
    const { values, setFieldValue } = useFormikContext()
    const inOrganization = useInOrganization()
    const inProPlan = useInProPlan()
    const inEnterprisePlan = useInEnterprisePlan()
    const inAgencyPlan = useInAgencyPlan()
    const maxPagesByPlan = !inOrganization ? 50 : inProPlan ? 500 : (inEnterprisePlan ? 5000 : inAgencyPlan ? 25000 : 50);
    const [maxPagesValue, setMaxPagesValue] = useState(values?.maxPages || maxPagesByPlan)
    const [showTooltip, setShowTooltip] = useState(false)
    const isMin = (maxPagesByPlan === 50 || inProPlan)
    const isAtPageLimit = maxPagesValue === maxPagesByPlan 
    return (
        <VStack w="full" spacing={5} alignItems="start">
            <Heading fontSize="md" fontWeight="semibold">Max Pages</Heading>
            <Stack direction="row" w="full" spacing={6}>
                <Flex fontSize="md" fontWeight="medium" color="fg.muted">{isMin ? 0 : 100}</Flex>
                <Flex w="full" justifyContent="space-between">
                    <Slider
                        isDisabled={isMin}
                        focusThumbOnChange={false}
                        value={maxPagesValue}
                        min={isMin ? 0 : 100}
                        max={maxPagesByPlan}
                        step={100}
                        onMouseEnter={() => setShowTooltip(true)}
                        onMouseLeave={() => setShowTooltip(false)}
                        onChange={(val) => {
                            if (!showTooltip) setShowTooltip(true);
                            setMaxPagesValue(val)
                        }}
                        onChangeEnd={(val) => {
                            setShowTooltip(false);
                            setMaxPagesValue(val);
                            setFieldValue("maxPages", val);
                        }}
                    >
                        <SliderTrack>
                            <Box position='relative' right={10} />
                            <SliderFilledTrack />
                        </SliderTrack>
                        <SliderThumb boxSize={6} />
                        <Tooltip
                            hasArrow
                            placement='top'
                            variant="rounded-sm"
                            isOpen={showTooltip}
                            label={`${maxPagesValue.toLocaleString()} pages`}
                        >
                            <SliderThumb boxSize={6} />
                        </Tooltip>
                    </Slider>
                </Flex>
                <Flex fontSize="md" fontWeight="medium" color="fg.muted" minWidth="fit-content">{`${maxPagesByPlan.toLocaleString()} pages`}</Flex>
            </Stack>
            <Stack w="full" direction="row" fontSize='sm'>
                <Text>
                    {`We'll finish ${isCrawl ? "crawling" : "importing"} ${inAgencyPlan ? "once we" : "early if"} we reach ${maxPagesValue?.toLocaleString()} pages.`}
                </Text>
                {isAtPageLimit && (
                    <UpgradePopover
                        forWhy
                        data={{ retrieved: { limit: maxPagesByPlan } }}
                        isCrawl={isCrawl}
                        button={<Button
                            fontWeight="medium"
                            variant="link"
                            _hover={{ cursor: "pointer" }}
                            rightIcon={<Icon as={BsQuestionCircle} fontSize="sm" mt={0.5} color="fg.muted" />}
                            textDecor="underline"
                            textDecorationColor="rarchy-link"
                            textUnderlineOffset={4.5}
                            fontSize="sm"
                            ml={1}
                            color="rarchy-link"
                        >
                            Why?
                        </Button>} />
                )}
            </Stack>
        </VStack>
    )
}