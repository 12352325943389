import { barHeight, barWidth } from '..';
import { filter, groupBy, isEmpty, isEqual, max, partition, sortBy, sum, take } from 'lodash'
import { getCanvasTextEditor, getEditor, getInUserFlow, getSitemap } from '../../../../helpers';
import { getPageSectionsHeight, setSectionAttrs } from '../canvas/components/page-sections';
import { getX, getY, shouldShowCovers } from '../canvas/utils/helpers';
import { minmax, render as renderPages } from '../canvas/render';
import { setNodeHeights, setNodesAndLinks, setWebsiteSections } from '../../../../store/actions/sitemap-actions';

import canvasTxt from '../canvas/components/text/canvasTxt'
import { dragging } from '../canvas/utils/drag';
import { flextree } from 'd3-flextree';
import { getPageName } from '../../utils/app';
import { load as loadNodes } from './nodes';
import { store } from '../../../../store';
import { stringify } from 'flatted';
import { update as updateUserFlow } from '../../user-flows/helpers';

export const WEBSITE_SECTIONS_MARGIN = 90;

export const load = ({ nodes, links, root, opts }) => {

  const sitemap = getSitemap()

  if (!sitemap?.format) return;
  //
  if (sitemap?.format === 'nodes') return loadNodes(root, opts);
  if (sitemap?.format !== 'nodes') nodes = updateNodesHeights(nodes);
  // update tree layout
  if (sitemap?.format.includes('tree')) nodes = updateTreeLayout(nodes, root);
  // set page section attrs
  nodes = setSectionAttrs({ nodes });
  // set website sections
  nodes = setWebsiteSectionAttributes({ nodes });
  // set nodes and links in redux (only if changed)
  if (((stringify(sitemap?.data?.nodes) !== stringify(nodes)) || (stringify(sitemap?.data?.links) !== stringify(links)))) {
    store.dispatch(setNodesAndLinks({ nodes, links }));
  };
  // render canvas!
  const inUserFlow = getInUserFlow()
  !inUserFlow ? renderPages(nodes, links) : updateUserFlow();

};

const setWebsiteSectionAttributes = ({ nodes }) => {

  const editor = getEditor()
  const sitemap = getSitemap()

  if (sitemap?.data?.section?.id === 'home') return nodes;

  const inSubfolder = sitemap?.data?.section;

  const { RevisionHistoryDrawer } = editor?.ui || {};

  const websiteSectionsDocData = !RevisionHistoryDrawer.showing ? sitemap?.docs?.website_sections : RevisionHistoryDrawer?.docs?.website_sections;

  const sectionsArr = !websiteSectionsDocData ? [] : sortBy(Object.keys(websiteSectionsDocData).map(id => { return { id, ...websiteSectionsDocData[id] } }), 'index'); // sort by index

  const mainNodesOrWebsiteSections = partition(nodes, (n) => !n.website_section);
  const mainNodes = inSubfolder ? nodes : mainNodesOrWebsiteSections[0];
  const websiteSectionNodes = inSubfolder ? [] : mainNodesOrWebsiteSections[1]?.filter(n => n.parent);

  const { min: minXFromMain, max: maxXFromMain } = minmax(mainNodes.filter(d => d.depth === 1), 'x');
  let { max: maxY } = minmax(mainNodes, 'y');

  const isTree = sitemap?.format === 'tree';

  if (!inSubfolder) {

    const websiteSectionNodesGroupedByParent = groupBy(websiteSectionNodes, 'website_section');

    const EMPTY_HEIGHT = (sitemap?.showCovers ? 266 : 104) + (WEBSITE_SECTIONS_MARGIN * 2); // (page height)

    if (!inSubfolder && isEmpty(sectionsArr)) sectionsArr.push({ id: 'empty' });

    // default width is length of first row of pages
    let widthOfNodes = !isTree ? ((maxXFromMain + 225) - minXFromMain) : 775;
    // min width
    if (widthOfNodes < 735) widthOfNodes = 735;

    const sectionsObj = {};

    sortBy(sectionsArr, 'index').forEach((arr, i) => {

      /*** change width to first row of ANY website_section if that width is greater than main nodes width ***/ // this should only happen in rare cases
      if (!isEmpty(websiteSectionNodes)) {
        const { min: minXFromFirstRowOfWebsiteSection, max: maxXFromFirstRowOfWebsiteSection } = minmax(websiteSectionNodes, 'x');
        const widthOfWebsiteSection = (maxXFromFirstRowOfWebsiteSection + 225) - minXFromFirstRowOfWebsiteSection;
        if (widthOfWebsiteSection > widthOfNodes) widthOfNodes = widthOfWebsiteSection;
      }
      /*** change width to first row of ANY website_section if that width is greater than main nodes width ***/

      const isWebsiteSectionEmpty = !websiteSectionNodesGroupedByParent[arr.id];
      let obj = { ...arr, x: (getX(mainNodes[0]) + (!isTree && (112.5 - (widthOfNodes / 2)))), y: maxY + (WEBSITE_SECTIONS_MARGIN * 1.5), width: widthOfNodes, height: !isWebsiteSectionEmpty ? 0 : EMPTY_HEIGHT, isEmpty: isWebsiteSectionEmpty } // default (main node) i === 0
      if (arr.index !== 0) { // override y based on previous website section height
        const previousSectionDocData = sectionsArr?.[i - 1];
        const nodesFromPreviousSection = websiteSectionNodes.filter(n => { if (n.website_section === previousSectionDocData?.id) return n; });
        let { max: maxY } = minmax(nodesFromPreviousSection, 'y');
        if (!isFinite(maxY)) maxY = (sectionsObj[previousSectionDocData?.id]?.y + (sectionsObj[previousSectionDocData?.id]?.height - WEBSITE_SECTIONS_MARGIN));
        obj.y = maxY + WEBSITE_SECTIONS_MARGIN;
        if (Math.sign(obj.y) !== 1) obj.y = -obj.y;
        obj.height = !isWebsiteSectionEmpty ? (obj.y - sectionsObj[previousSectionDocData?.id]?.y) : EMPTY_HEIGHT;
      }

      sectionsObj[arr.id] = obj;

    });

    if (!isEqual(sitemap?.data?.website_sections, sectionsObj)) {
      store.dispatch(setWebsiteSections(sectionsObj));
    }
  }

  return nodes;

}

// create tree layout
export const updateTreeLayout = (nodes, root) => {

  const sitemap = getSitemap()

  let { section } = sitemap?.data;

  const inSubfolder = !isEmpty(section);

  /*** flex layout for dynamic heights ***/
  const layout = flextree({ children: data => data.children, nodeSize: node => [node.width, node.height], spacing: (a, b) => a.parent === b.parent ? sitemap.showCovers ? 15 : 7.5 : 60 });
  layout(root);
  // do website sections
  if (!inSubfolder) root?.website_sections?.forEach(s => layout(s))
  /*** flex layout for dynamic heights ***/

  const PAGE_WIDTH = 225;

  if (sitemap?.format === 'tree') {

    // y is x, x is y
    nodes.map(node => {
      const normalHeight = sitemap?.showCovers ? 266.5 : 59.5;
      if (node.nodeHeight > normalHeight) node.x = node.x - ((node.nodeHeight - normalHeight) / 2);
      return node;
    });

    const mainNodesOrWebsiteSections = partition(nodes, (n) => !n.website_section);
    const websiteSectionNodes = inSubfolder ? [] : mainNodesOrWebsiteSections[1]?.filter(n => n.parent);
    //
    const websiteSectionNodesGroupedByParent = groupBy(websiteSectionNodes, 'website_section');

    Object.keys(websiteSectionNodesGroupedByParent).forEach(id => {
      const websiteSectionData = sitemap?.data?.website_sections?.[id];
      const arr = websiteSectionNodesGroupedByParent[id];
      if (!isEmpty(arr)) {
        let min = 0; arr.forEach(d => { if (d.x < min) min = d.x; });
        const minY = websiteSectionData ? Math.abs(websiteSectionData.y - min) : 0;
        arr.forEach(node => {
          node.x = node.x + minY + WEBSITE_SECTIONS_MARGIN;
          node.y = node.depth === 1 ? root.y : (node.y = node.parent ? node.parent.y + (sitemap?.showCovers ? 207.5 : 183) + WEBSITE_SECTIONS_MARGIN : 0)
        })
      }
    })
  }

  if (sitemap?.format === 'tree-vertical-matrix') {

    const MARGIN = sitemap.showCovers ? 45 : 37.5;
    const PAGE_WIDTH_PLUS_MARGIN = PAGE_WIDTH + MARGIN;

    /*** WIDTHS + X ***/

    const widths = [];

    var depth1 = filter(nodes.filter(n => !n.website_section), { 'depth': 1 });
    const maxWidth = depth1.length * barWidth;
    const estimatedFirstX = -(((depth1.length * PAGE_WIDTH_PLUS_MARGIN) / 2) - (PAGE_WIDTH_PLUS_MARGIN / 2));

    for (let index = 0; index < depth1.length; index++) {
      /*** keep minimum distance between top level of nodes ***/
      depth1[index].x = estimatedFirstX + (index * PAGE_WIDTH_PLUS_MARGIN);
      /*** keep minimum distance between top level of nodes ***/
      /*** calculate width of all children of node ***/
      var depths = [];
      /* eslint-disable-next-line */
      function recurse(node, i) {
        depths.push(node.depth);
        if (node.children) node.children.forEach(recurse);
      }
      if (depth1[index].children) depth1[index].children.forEach(recurse);
      /*** calculate width of all children of node ***/
      const distance = max(depths) ? max(depths) * 45 : 0;
      widths.push(distance);
    };

    /*** center top level of nodes ***/
    const newMaxWidth = depth1.length * barWidth + sum(take(widths, depth1.length - 1)); // don't count last node in new maxWidth calculations
    for (let index = 0; index < depth1.length; index++) {
      depth1[index].x = depth1[index].x + sum(take(widths, index)) - ((newMaxWidth - maxWidth) / 2);
    };
    /*** center top level of nodes ***/

    /*** WIDTHS + X ***/

    sortBy(nodes, 'depth').forEach(d => {
      const websiteSectionId = !inSubfolder && d.website_section;
      if (websiteSectionId && d.depth === 0) { // homepage
        const websiteSectionData = sitemap?.data?.website_sections?.[websiteSectionId];
        if (websiteSectionData) d.y = websiteSectionData.y + d.y;
      }
      if (d.depth === 1) {
        const parent = d;
        let parentY = (!websiteSectionId ? (parent.y + parent.nodeHeight) + MARGIN : parent.parent.y + WEBSITE_SECTIONS_MARGIN);
        let nodesHeight = !websiteSectionId ? 0 : MARGIN;
        const recurse = (node, i) => {
          if (websiteSectionId && node.depth === 1) {
            d.y = parentY;
          } else if (node.depth > 1) {
            node.y = !websiteSectionId ? (parentY + nodesHeight) : (parentY + parent.nodeHeight + nodesHeight);
            nodesHeight = nodesHeight + node.nodeHeight + MARGIN;
            node.x = node.parent.x + 50;
          }
          if (node.children) node.children.forEach(recurse);
        }
        recurse(d);
      }
    });
  }

  /*** website sections only ***/
  if (sitemap?.format === 'tree-vertical') {
    if (!inSubfolder) { // only if not in subfolder
      const websiteSectionNodes = nodes.filter(n => n.website_section);
      websiteSectionNodes.forEach((d, i) => {
        const websiteSectionId = d.website_section;
        if (d.depth === 0) { // homepage
          const websiteSectionData = sitemap?.data?.website_sections?.[websiteSectionId];
          if (websiteSectionData) d.y = websiteSectionData.y + d.y;
        } else if (d.depth === 1) { // top-level
          d.y = d.parent.y + WEBSITE_SECTIONS_MARGIN;
        } else { // all children nodes
          d.y = d.parent.y + d.parent.nodeHeight + 60;
        }
      });
    };
  }
  /*** website sections only ***/

  return nodes;

};

export const updateNodesHeights = nodes => {

  const sitemap = getSitemap()
  if (sitemap?.format === 'nodes') return;
  // 
  const heights = { _maxNodeHeight: 0 };
  // Store the old positions for transition;
  const mappedNodes = nodes.map(node => {
    const showCovers = shouldShowCovers(node);
    // get node heights
    const { actualTextHeight, textRectHeight, nodeHeight, pageSectionsHeight } = getNodeHeights(node, sitemap);
    // set max node height
    if (nodeHeight > heights._maxNodeHeight) heights._maxNodeHeight = nodeHeight;
    // set heights for node
    heights[node.id] = { actualTextHeight, textRectHeight, nodeHeight, pageSectionsHeight };
    //
    node.actualTextHeight = actualTextHeight;
    node.textRectHeight = textRectHeight;
    node.pageSectionsHeight = pageSectionsHeight;
    node.nodeHeight = nodeHeight;
    // set coords
    const { topOfNode, bottomOfNode, leftOfNode, rightOfNode } = getNodeCoords(node, sitemap)
    node.topOfNode = topOfNode;
    node.bottomOfNode = bottomOfNode;
    node.leftOfNode = leftOfNode;
    node.rightOfNode = rightOfNode;
    //
    if (sitemap?.format === 'tree') {
      node.height = 225 + (showCovers ? 72 : 48);
      node.width = node.nodeHeight + 30;
    } else {
      node.width = 225 + 30;
      node.height = node.nodeHeight + 60;
    }
    //
    return node;
  });

  // dispatch heights (if changed)
  if (!dragging && (JSON.stringify(sitemap?.data?.heights) !== JSON.stringify(heights))) {
    store.dispatch(setNodeHeights(heights));
  }
  // return nodes
  return mappedNodes;
};

export const getNodeHeights = (node, sitemap) => {
  const pageSectionsHeight = getPageSectionsHeight(node);
  const { textRectHeight, actualTextHeight } = getTextHeight(node, sitemap);
  const nodeHeight = getNodeHeight(node, textRectHeight, pageSectionsHeight);
  return { actualTextHeight, textRectHeight, nodeHeight, pageSectionsHeight };
}

export const getNodeCoords = (node, sitemap) => {
  const topOfNode = getTopOfNode(node, sitemap);
  const bottomOfNode = topOfNode + node.nodeHeight;
  const leftOfNode = node.x;
  const rightOfNode = node.x + 225; // this will always be 255 (distance from x, as x is always 0) (this is called before updating tree layout) 
  // IF WANTING TO MAKE THIS ATTRIBUTES AS SAME AS USER FLOW, (ABSOLUTE COORDS), NEED TO MAKE SURE THAT THESE ARE UPDATED WITH FORMAT CHANGES, AND INVERTED FOR TREE SAME AS X/Y ETC
  return { topOfNode, bottomOfNode, leftOfNode, rightOfNode }
}

const LINE_HEIGHT = 21;

const getTextHeight = (node, sitemap) => {

  const showCovers = shouldShowCovers(node);

  const nodeId = node?.id;
  const heights = sitemap?.data?.heights?.[nodeId] || {};

  var actualTextHeight, textRectHeight;

  const CanvasTextEditor = getCanvasTextEditor()
  const renamingPage = CanvasTextEditor.showing && !CanvasTextEditor.section && CanvasTextEditor.node.id === node.id;
  if (!renamingPage && heights['actualTextHeight']) actualTextHeight = heights['actualTextHeight'];

  if (!actualTextHeight) {

    const headerHeight = 10;
    var paddingTop = headerHeight + (showCovers ? 109 : 14);

    var maxWidth = showCovers ? 200 : 195;
    var lineHeight = LINE_HEIGHT;

    var fakeContext = document.createElement('canvas').getContext("2d");
    canvasTxt.fontSize = 17;
    canvasTxt.fontWeight = 600;
    canvasTxt.lineHeight = lineHeight;
    canvasTxt.font = 'Inter,sans-serif';
    canvasTxt.vAlign = 'top';

    var name = getPageName(renamingPage ? CanvasTextEditor.newString : node.name);
    const { height } = canvasTxt.drawText(fakeContext, name ? name : "", getX(node) + 12, getY(node) + paddingTop, maxWidth, lineHeight);
    actualTextHeight = height;
  }

  textRectHeight = heights['textRectHeight'] ? heights['textRectHeight'] : actualTextHeight + LINE_HEIGHT; // top-bottom margin

  return { textRectHeight, actualTextHeight };
};

const getNodeHeight = (node, textRectHeight, pageSectionsHeight) => {
  const showCovers = shouldShowCovers(node);
  return (showCovers ? 224.5 : 17.5) + textRectHeight + pageSectionsHeight;
};

const getTopOfNode = (d, sitemap) => {
  const inUserFlow = getInUserFlow()
  // const showCovers = inUserFlow ? (d.type === 'page' ? true : false) : sitemap?.showCovers;
  if (inUserFlow) {
    if (d.type && !d?.type?.includes('page')) return d.y - 24; // need to account for -24 to line up original svg y (all symbols apart from page)
  }
  return d.y;
};

const storeNodePositions = ({ nodes }) => {
  // Store the old positions for transition;
  return nodes.map(node => {
    node.x = Math.round(node.x);
    node.y = Math.round(node.y);
    //
    node.x0 = node.x;
    node.y0 = node.y;
    // delete planned positions
    delete node.y1;
    delete node.x1;
    //
    return node;
  });
};

export const getBarHeight = ({ node, sitemap }) => {
  const sectionsCount = sitemap?.data.page_sections ? sitemap?.data.page_sections[node.id] ? sitemap?.data.page_sections[node.id].length : 1 : 1;
  const textHeight = 40, headerHeight = 18, bottomPadding = 3;
  var barHeight = headerHeight + textHeight + (sectionsCount * 29) + bottomPadding;
  // add padding at bottom if required due to expand-collapse button
  if (sitemap?.format.includes('tree-vertical')) {
    const hasChildren = (node.parent && (node.children || node._children)) ? 6 : 0;
    barHeight = barHeight + hasChildren;
  };
  return barHeight;
};

// lines
export const createLinks = (d, node) => {
  const sitemap = getSitemap()
  if (sitemap?.format === 'tree') {
    if (d.x === d.parent.x) {
      return `M${d.y},${d.x + (!sitemap?.showCovers ? (17 / 2) : -15.5)}H${d.y + (d.parent.y - d.y) / 2}V${d.parent.x + (!sitemap?.showCovers ? (17 / 2) : -15.5)}H${d.parent.y
        }`;
    }
    if (d.x > d.parent.x) {
      return `M${d.y},${d.x + (!sitemap?.showCovers ? (17 / 2) : -15.5)}H${d.y + (d.parent.y - d.y) / 2}a2,2 0 0 1 -2,-2V${d.parent.x + (!sitemap?.showCovers ? (17 / 2) : -15.5)
        }H${d.parent.y}`;
    }
    if (d.x < d.parent.x) {
      return `M${d.y},${d.x + (!sitemap?.showCovers ? (17 / 2) : -15.5)}H${d.y + (d.parent.y - d.y) / 2}a-2,-2 0 0 0 -2,2V${d.parent.x + (!sitemap?.showCovers ? (17 / 2) : -15.5)
        }H${d.parent.y}`;
    }
  }
  if (sitemap?.format === 'tree-vertical') {
    if (d.x === d.parent.x) {
      return `M${d.x},${d.y}V${d.y},${(d.y + d.parent.y) / 2}H${d.parent.x}V${d.parent.y}`;
    }
    if (d.x > d.parent.x) {
      return `M${d.x},${d.y}V${d.y},${(d.y + d.parent.y) / 2}a-2,2 0 0 0 -2,-2H${d.parent.x}V${d.parent.y}`;
    }
    if (d.x < d.parent.x) {
      return `M${d.x},${d.y}V${d.y},${(d.y + d.parent.y) / 2}a2,-2 0 0 1 2,-2H${d.parent.x}V${d.parent.y}`;
    }
  }
  if (sitemap?.format === 'tree-vertical-matrix') {
    /*** for dragging */
    if (d.side) {
      if (d.side === 'above' || d.side === 'below') return `M${d.x/* - 112.5 */},${d.y + (sitemap?.showCovers ? 12.5 : 37)}H${d.parent.x - 93}a-3,3 0 0 1 -3,-3V${d.parent.y}`;
      if (d.side === 'left' || d.side === 'right') {
        if (d.x === d.parent.x) return `M${d.x},${d.y}V${d.y},${(d.y + d.parent.y) / 2}H${d.parent.x}V${d.parent.y}`;
        if (d.x > d.parent.x) return `M${d.x},${d.y}V${d.y},${(d.y + d.parent.y) / 2}a-2,2 0 0 0 -2,-2H${d.parent.x}V${d.parent.y}`;
        if (d.x < d.parent.x) return `M${d.x},${d.y}V${d.y},${(d.y + d.parent.y) / 2}a2,-2 0 0 1 2,-2H${d.parent.x}V${d.parent.y}`;
      }
    }
    /*** for dragging */
    if (node && node.depth > 1) return `M${d.x/* - 112.5 */},${d.y + (sitemap?.showCovers ? 12.5 : 37)}H${d.parent.x - 93}a-3,3 0 0 1 -3,-3V${d.parent.y}`;
    if (d.x === d.parent.x) return `M${d.x},${d.y}V${d.y},${(d.y + d.parent.y) / 2}H${d.parent.x}V${d.parent.y}`;
    if (d.x > d.parent.x) return `M${d.x},${d.y}V${d.y},${(d.y + d.parent.y) / 2}a-2,2 0 0 0 -2,-2H${d.parent.x}V${d.parent.y}`;
    if (d.x < d.parent.x) return `M${d.x},${d.y}V${d.y},${(d.y + d.parent.y) / 2}a2,-2 0 0 1 2,-2H${d.parent.x}V${d.parent.y}`;
  }
};