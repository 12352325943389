import { Button, Heading, Stack } from "@chakra-ui/react"
import { getOrganizationId, toTitleCase } from "../../../../helpers"
import { setUpgradeModalScreen, toggleUpgradeModal } from "../../../../store/actions/ui-actions"

import { ConfirmedList } from "./List"
import { switchAccount } from "../../../../store/actions/auth-actions"
import { useDispatch } from "react-redux"
import { useEffect } from "react"
import { useGetUpgradeModal } from "../../../../hooks"

export const Confirmed = () => {

    const dispatch = useDispatch()

    const { planId, stripeRedirectAttrs } = useGetUpgradeModal()
    const currentOrganization = getOrganizationId()

    const { orgId, billingInterval, priceInUSD } = stripeRedirectAttrs
    const inAgency = planId === 'agency'

    // on mount
    useEffect(() => {
        setTimeout(() => {
            if (currentOrganization !== orgId) dispatch(switchAccount(orgId))
            try {
                window.dataLayer.push({ 'event': 'new_trial_signup', 'new_plan_id': planId, 'new_plan_amount': priceInUSD * (billingInterval === 'year' ? 10 : 1), billing_interval: billingInterval });

            } catch (e) {
                console.error(e)
            }
        }, 2500);
        // on unmount
        return () => {
            if (currentOrganization !== orgId) dispatch(switchAccount(orgId))
        }
    }, [])

    return (
        <Stack spacing={8}>
            <Heading fontSize="2xl" color="fg.default">{`Welcome to Rarchy ${toTitleCase(planId)}!`}</Heading>
            <ConfirmedList />
            <Button
                colorScheme="blue"
                fontSize="sm"
                onClick={() => {
                    // continue
                    if (inAgency) {
                        dispatch(setUpgradeModalScreen({ screen: 'confirmed-invite-team' }))
                    } else {
                        dispatch(toggleUpgradeModal({ showing: false }))
                    }
                }}
            >
                Let's get started
            </Button>
        </Stack>
    )
}