import { Box, ListItem, UnorderedList, VStack } from "@chakra-ui/react"
import { FolderPermissionsListItem, InviteTeamListItem, PageSectionsListItem, ScreenshotsPageColorsAndMoreListItem, UnlimitedPagesPlusImportListItem } from "../Components/Messaging/ListItems"
import { TRIAL_DAYS, useCanStartNewTrial } from "../../../../shared/hooks/pricing"
import { getCollectionLabelFromDoc, toTitleCase } from "../../../helpers"

import { WhatYoullGet } from "../Components/Messaging/WhatYoullGet"
import { useGetUpgradeModal } from "../../../hooks"

export const NewFile = () => {
    const canStartNewTrial = useCanStartNewTrial()
    const { planId, screen } = useGetUpgradeModal()
    const collection = screen.split("_")?.[1]
    const itemLabel = getCollectionLabelFromDoc({ collection })
    const isTeamPlan = planId === 'team'
    const isUserFlows = collection === 'user-flows'
    return (
        <VStack key="default" fontSize="sm" align="start" mt={3} spacing={5}>
            <Box>
                {`Your Rarchy Free account is limited to one ${itemLabel}. To `}<strong>{`unlock unlimited ${itemLabel}s`}</strong>{`, ${canStartNewTrial ? `start your free ${TRIAL_DAYS}-day trial of` : 'upgrade to'} Rarchy ${toTitleCase(planId)}.`}
            </Box>
            <WhatYoullGet />
            <UnorderedList spacing={4} mt={-1.5}>
                {!isUserFlows && (
                    isTeamPlan ?
                        <>
                            <UnlimitedPagesPlusImportListItem />
                            <PageSectionsListItem />
                            <ScreenshotsPageColorsAndMoreListItem />
                        </> :
                        <>
                            <UnlimitedPagesPlusImportListItem />
                            <InviteTeamListItem />
                            <FolderPermissionsListItem />
                        </>
                )}
                {isUserFlows && (
                    <>
                        <ListItem>
                            Add unlimited steps to your user flows
                        </ListItem>
                        <ListItem>
                            Collaborate with others by granting edit access to your user flows
                        </ListItem>
                        <ListItem>
                            Never lose your work with revision history, archiving, <strong>and much more</strong>
                        </ListItem>
                    </>
                )}
            </UnorderedList>
        </VStack>
    )
}