import {
    Box,
    Button,
    Checkbox,
    FormControl,
    FormLabel,
    Grid,
    HStack,
    Heading,
    Icon,
    IconButton,
    Input,
    Textarea,
    VStack
} from "@chakra-ui/react"
import React, { useEffect } from 'react'
import { getSettingsFromStorage, onSettingsChange } from './helpers';
import { useGetSitemap, useInOrganization } from '../../../../../../hooks';

import { BsQuestionCircle } from 'react-icons/bs';
import { UnlockIcon } from '@chakra-ui/icons';
import { compact } from 'lodash'
import { toggleUpgradeModal } from "../../../../../../store/actions/ui-actions";
import { useDispatch } from "react-redux";

export const Settings = ({ showTitle, inRecurring, settings, setSettings, isDisabled }) => {
    const dispatch = useDispatch()
    const sitemap = useGetSitemap()
    const inOrganization = useInOrganization()
    useEffect(() => {
        getSettingsFromStorage(compact([inRecurring ? '' : 'fullpage', 'lazyload', 'waitForFiveSeconds', 'hidden', 'username']), settings, setSettings, sitemap?.id)
    }, [])
    return (
        <>
            {showTitle && <Heading size="sm" ml={-3} mt={3} mb={7} fontWeight="semibold">Options</Heading>}
            <VStack spacing={7} alignItems="baseline">
                <HStack direction="row" spacing={5}>
                    {!inRecurring && (
                        <Checkbox
                            isChecked={inOrganization ? settings?.fullpage : false}
                            size="sm"
                            isDisabled={!inOrganization ? true : isDisabled}
                            onChange={(e) => onSettingsChange('fullpage', e, settings, setSettings, sitemap?.id)}
                        >
                            Full Page
                        </Checkbox>
                    )}
                    <Checkbox isChecked={settings.lazyload} size="sm" isDisabled={isDisabled} onChange={(e) => onSettingsChange('lazyload', e, settings, setSettings, sitemap?.id)}>Lazy Load<IconButton ml={1} size="xs" rounded="full" variant="ghost" icon={<BsQuestionCircle />} /></Checkbox>
                    <Checkbox isChecked={settings.waitForFiveSeconds} size="sm" isDisabled={isDisabled} onChange={(e) => onSettingsChange('waitForFiveSeconds', e, settings, setSettings, sitemap?.id)}>Wait 5 Seconds</Checkbox>
                </HStack>
                {!inOrganization && (
                    <HStack fontSize="sm" mt={-4} align="center" borderTop="1px solid" borderColor="rarchy-border" pt={2}>
                        <Icon as={UnlockIcon} color="fg.muted" />
                        <Box>
                            Full-page screenshots are only available in our <Button
                                size="sm"
                                variant="link"
                                color="rarchy-link"
                                fontWeight="medium"
                                onClick={() => {
                                    dispatch(toggleUpgradeModal({ showing: true, planId: 'team', screen: 'screenshots' }))
                                }}
                            >
                                paid plans
                            </Button>
                            .
                        </Box>
                    </HStack>
                )}
                <FormControl id="elements" isDisabled={isDisabled}>
                    <FormLabel fontSize="sm">Hide Elements<IconButton ml={1} size="xs" rounded="full" variant="ghost" icon={<BsQuestionCircle />} /></FormLabel>
                    <Textarea fontSize="sm" type="text" placeholder=".overlay &#10; #modal" value={settings.hidden} onChange={(e) => onSettingsChange('hidden', e, settings, setSettings, sitemap?.id)} />
                </FormControl>
                <FormControl id="auth" isDisabled={isDisabled}>
                    <FormLabel fontSize="sm">HTTP Basic Auth</FormLabel>
                    <Grid templateColumns="repeat(2, 1fr)" gap={3}>
                        <Input fontSize="sm" autoComplete="new-password" type="text" placeholder="Username" defaultValue={settings.httpAuth?.username ? settings.httpAuth.username : ""} onChange={(e) => onSettingsChange('username', e, settings, setSettings, sitemap?.id)} />
                        <Input fontSize="sm" autoComplete="new-password" type="password" placeholder="Password" onChange={(e) => onSettingsChange('password', e, settings, setSettings, sitemap?.id)} />
                    </Grid>
                </FormControl>
            </VStack>
        </>
    )
}