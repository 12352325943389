import {
    IconButton,
    Tooltip,
    useColorModeValue as mode,
} from "@chakra-ui/react"
import { getCustomCover, getPageColor } from '../canvas/utils/helpers'

import { FaFolderOpen } from 'react-icons/fa';
import React from 'react';
import { getPalletteColors } from '../../../../store/actions/editor-actions';
import { togglePageDrawer } from '../../../../store/actions/sitemap-actions';
import { transform } from '../canvas/utils/zoom';
import { useDispatch } from 'react-redux';

const HEIGHT = 50;

export const PageDrawerButton = ({ d, x, y }) => {
    const dispatch = useDispatch()
    const fontSize = `${transform.k * HEIGHT}px`
    const image = getCustomCover(d);
    const left = x + ((image ? 100 : 137.5) * transform.k) - (58.5 * transform.k), top = (y + (102 * transform.k))
    const color = getPageColor(d)
    const pallette = getPalletteColors(color)
    return (
        <Tooltip variant="rounded-sm" label="Open Drawer" placement="bottom" hasArrow openDelay={1000}>
            <IconButton
                icon={<FaFolderOpen />}
                fontSize={fontSize}
                h={`${HEIGHT * transform.k}px`}
                p={`${9 * transform.k}px`}
                pr={`${7 * transform.k}px`}
                minW={0}
                left={left}
                top={top}
                variant="ghost"
                pos="absolute"
                color={color}
                _hover={{ bgColor: mode(pallette[0], "whiteAlpha.200") }}
                onClick={(e) => {
                    e.stopPropagation()
                    dispatch(togglePageDrawer({ showing: true, node: d }))
                }}
            />
        </Tooltip>
    )
}