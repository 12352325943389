import { Alert, Box, Button, HStack, Progress, VStack } from "@chakra-ui/react";
import { useInOrganization, useInProPlan } from "../../../../../../hooks";

import { EstimatePopover } from "./EstimatePopover";
import { Icon } from "@chakra-ui/icons";
import { MdTipsAndUpdates } from "react-icons/md";
import { inRange } from 'lodash'
import { toggleUpgradeModal } from "../../../../../../store/actions/ui-actions";
import { useCanStartNewTrial } from "../../../../../../../shared/hooks/pricing";
import { useDispatch } from "react-redux";

export const UpgradeBanner = ({ data }) => {

    const dispatch = useDispatch()

    const canStartNewTrial = useCanStartNewTrial()
    const isFreeUser = !useInOrganization()
    const inProPlan = useInProPlan()

    const isCrawl = data?.type === 'crawl'
    // total pages (estimate if crawl)
    const totalPageCount = data?.retrieved?.[isCrawl ? 'estimate' : 'total']
    // percent
    const percent = Math.round(data?.retrieved?.count / totalPageCount)
    // show banner
    const showBanner = (isFreeUser || inProPlan) && inRange(totalPageCount, data?.retrieved?.limit, 5000)
    // continue
    return showBanner ? (
        <Box mt={6} w={`calc(100% - 66px) `} ml={16}>
            <Alert bgColor="transparent" fontSize="15px" fontWeight="normal" color="fg.muted" py={3} rounded="md">
                <HStack spacing={5}>
                    <Icon ml={5} as={MdTipsAndUpdates} fontSize="4xl" color="#ECC94B" />
                    <VStack align="start">
                        <Box>
                            {`Your plan allows you to ${isCrawl ? 'crawl' : 'import'} ${data?.retrieved?.limit?.toLocaleString()} of ${isCrawl ? 'an' : ''} `}
                            {isCrawl && <EstimatePopover data={data} />}
                            {` ${totalPageCount?.toLocaleString()} pages - that's ${percent < 1 ? 'less than' : 'just'} ${percent || 1}% of your website.`}
                        </Box>
                        <Box>
                            <Button
                                mr={1.5}
                                fontWeight="medium"
                                fontSize="15px"
                                variant="link"
                                color="rarchy-link"
                                onClick={(e) => {
                                    e.preventDefault();
                                    e.stopPropagation();
                                    dispatch(toggleUpgradeModal({ showing: true, planId: 'team', screen: 'pages-per-import', lockPlan: true }))
                                }}
                            >
                                <>
                                    {canStartNewTrial && "Start a free Rarchy Team trial"}
                                    {!canStartNewTrial && "Upgrade to Rarchy Team"}
                                </>
                            </Button>{`to ${isCrawl ? 'crawl' : 'import'} up to 5,000 pages per sitemap.`}
                        </Box>
                    </VStack>
                </HStack>
            </Alert>
            <Progress ml="90px" value={percent} bgColor="rarchy-bg-subtle-hover-200" colorScheme="blue" h={0.5} />
        </Box>
    ) : null
}